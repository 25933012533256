/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Absence = {
  __typename?: 'Absence';
  absenceDate?: Maybe<Scalars['Float']['output']>;
  absenceReason?: Maybe<Scalars['String']['output']>;
  absenceStatus?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  durationInPeriod?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  leaveType?: Maybe<Scalars['String']['output']>;
  returnDate?: Maybe<Scalars['Float']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type AbsenceSearch = {
  absenceDate?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AbsenceSearchTeam = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AccommodationInputType = {
  barriersToInvolvement?: InputMaybe<Scalars['String']['input']>;
  cleanlinessAndOrganisation?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type AccommodationType = {
  __typename?: 'AccommodationType';
  barriersToInvolvement?: Maybe<Scalars['String']['output']>;
  cleanlinessAndOrganisation?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type ActivateMemberChatRoomInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ActivitiesPlan = {
  __typename?: 'ActivitiesPlan';
  activities?: Maybe<Array<Maybe<ActivityPlan>>>;
  category: Scalars['String']['output'];
  customerActivitiesId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  personalOutcome: Scalars['String']['output'];
};

export type ActivitiesPlanInput = {
  activities?: InputMaybe<Array<InputMaybe<ActivityPlanInput>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  customerActivitiesId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  personalOutcome?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityPlan = {
  __typename?: 'ActivityPlan';
  completed?: Maybe<Scalars['Boolean']['output']>;
  customerActivityId: Scalars['String']['output'];
  frequency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  repeat?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['Float']['output']>;
  timeOfDay?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  who: Scalars['String']['output'];
};

export type ActivityPlanInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  customerActivityId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  repeat?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  timeOfDay?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  who?: InputMaybe<Scalars['String']['input']>;
};

export type AddActivityToShiftInput = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AddAgreedShiftInput = {
  agreedRotaId: Scalars['String']['input'];
  agreedShiftRunId: Scalars['String']['input'];
  day: Scalars['Int']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type AddAgreedShiftRunInput = {
  agreedRotaId: Scalars['String']['input'];
  endDateTime: Scalars['Float']['input'];
  hours: Scalars['Int']['input'];
  startDateTime: Scalars['Float']['input'];
  teamId: Scalars['String']['input'];
  week: Scalars['Int']['input'];
};

export type AddAgreedVisitInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftRunId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AddMileageReportingHourInput = {
  date?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Float']['input']>;
  mileageDestination?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AddMultipleAgreedVisitInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftRunId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  shiftRunIndex?: InputMaybe<Scalars['Int']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  untilDate?: InputMaybe<Scalars['Float']['input']>;
};

export type AddVisitToShift = {
  item: AddVisitToShiftItem;
  rotaId: Scalars['String']['input'];
};

export type AddVisitToShiftItem = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  endDateTime: Scalars['Float']['input'];
  isToBeRescheduled?: InputMaybe<Scalars['String']['input']>;
  oneOffVisitNote?: InputMaybe<Scalars['String']['input']>;
  oneOffVisitReason?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['String']['input'];
  startDateTime: Scalars['Float']['input'];
  teamId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AddressType = {
  __typename?: 'AddressType';
  access_details?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<CoordinatesType>;
  formatted_address?: Maybe<Scalars['String']['output']>;
};

export type AffectedShiftSearch = {
  rotaId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  visitId: Scalars['String']['input'];
};

export type AgentInput = {
  browser?: InputMaybe<BrowserInput>;
  device?: InputMaybe<DeviceInput>;
  family?: InputMaybe<Scalars['String']['input']>;
  major?: InputMaybe<Scalars['String']['input']>;
  minor?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<DeviceInput>;
  patch?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type AgreedRota = {
  __typename?: 'AgreedRota';
  endRotaDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startRotaDateTime?: Maybe<Scalars['Float']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type AgreedRotaInput = {
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AgreedRotaSearch = {
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AgreedShift = {
  __typename?: 'AgreedShift';
  agreedRotaId: Scalars['String']['output'];
  agreedShiftRunId: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
  visits?: Maybe<Array<Maybe<AgreedVisitType>>>;
};

export type AgreedShiftRun = {
  __typename?: 'AgreedShiftRun';
  agreedRotaId: Scalars['String']['output'];
  endDateTime?: Maybe<Scalars['Float']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  shifts?: Maybe<Array<Maybe<AgreedShift>>>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
};

export type AgreedShiftRunSearch = {
  agreedRotaId: Scalars['String']['input'];
  agreedShiftRunId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['String']['input'];
};

export type AgreedShiftRunWeeks = {
  __typename?: 'AgreedShiftRunWeeks';
  week1?: Maybe<Array<Maybe<AgreedShiftRun>>>;
  week2?: Maybe<Array<Maybe<AgreedShiftRun>>>;
};

export type AgreedShiftRunWeeksOptimised = {
  __typename?: 'AgreedShiftRunWeeksOptimised';
  unsolvedDays?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  week1?: Maybe<Array<Maybe<AgreedShiftRun>>>;
  week2?: Maybe<Array<Maybe<AgreedShiftRun>>>;
};

export type AgreedShiftSearch = {
  agreedRotaId: Scalars['String']['input'];
  agreedShiftRunId: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type AgreedVisitType = {
  __typename?: 'AgreedVisitType';
  agreedRotaId?: Maybe<Scalars['String']['output']>;
  agreedShiftId?: Maybe<Scalars['String']['output']>;
  agreedShiftRunId?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  endDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type Allergies = {
  __typename?: 'Allergies';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  riskLevel: Scalars['String']['output'];
};

export type AllergiesInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  riskLevel: Scalars['String']['input'];
};

export type Answer = {
  __typename?: 'Answer';
  createdDateTime: Scalars['Float']['output'];
  focus: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  questionId: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  supportWorkerId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  whyScore: Scalars['String']['output'];
};

export type AnswerInput = {
  createdDateTime: Scalars['Float']['input'];
  focus: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
  score: Scalars['Int']['input'];
  supportWorkerId: Scalars['String']['input'];
  whyScore: Scalars['String']['input'];
};

export type AnswerSearch = {
  createdDateTime?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId: Scalars['String']['input'];
};

export type AnswerTimes = {
  __typename?: 'AnswerTimes';
  c4?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c7?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c10?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c13?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c14?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c16?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c17?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c19?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c22?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c23?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c24?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c24a?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  c25?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customerCards?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  dt4?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hi3?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  interestsCards?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip1?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip3?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip4?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip5?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip6?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip7?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip8?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip9?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip10?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip11?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip12?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip13?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip14?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip15?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip16?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip17?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip18?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip19?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip20?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip21?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip22?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip23?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip24?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip25?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip26?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip27?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip28?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip29?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ip30?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p1a?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p3?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p4?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p5?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p6?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p7?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p8?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p9?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p10?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p11?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p12?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p13?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p14?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p15a?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p16a?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p17?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p18?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p19a?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  p20b?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  personalityCards?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  valuesCards?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv4?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv5?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv8?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv12?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv21a?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv23?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv26?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv28?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv29?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv32?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv33?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv34?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv37?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yv38?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AnswerTimesInput = {
  c4?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c7?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c10?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c13?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c14?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c16?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c17?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c19?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c22?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c23?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c24?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c24a?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  c25?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customerCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dt4?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hi3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  interestsCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip1?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip4?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip5?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip6?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip7?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip8?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip9?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip10?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip11?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip12?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip13?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip14?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip15?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip16?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip17?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip18?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip19?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip20?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip21?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip22?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip23?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip24?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip25?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip26?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip27?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip28?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip29?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ip30?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p1a?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p4?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p5?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p6?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p7?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p8?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p9?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p10?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p11?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p12?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p13?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p14?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p15a?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p16a?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p17?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p18?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p19a?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  p20b?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personalityCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  valuesCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv4?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv5?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv8?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv12?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv21a?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv23?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv26?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv28?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv29?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv32?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv33?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv34?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv37?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  yv38?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AnswersItem = {
  __typename?: 'AnswersItem';
  c4?: Maybe<Scalars['Boolean']['output']>;
  c7?: Maybe<Scalars['Boolean']['output']>;
  c10?: Maybe<Scalars['Boolean']['output']>;
  c13?: Maybe<Scalars['Boolean']['output']>;
  c14?: Maybe<Scalars['Boolean']['output']>;
  c16?: Maybe<Scalars['Boolean']['output']>;
  c17?: Maybe<Scalars['Boolean']['output']>;
  c19?: Maybe<Scalars['Boolean']['output']>;
  c22?: Maybe<Scalars['Boolean']['output']>;
  c23?: Maybe<Scalars['Boolean']['output']>;
  c24?: Maybe<Scalars['Boolean']['output']>;
  c24a?: Maybe<Scalars['Boolean']['output']>;
  c25?: Maybe<Scalars['Boolean']['output']>;
  dt4?: Maybe<Scalars['Boolean']['output']>;
  hi3?: Maybe<Scalars['Boolean']['output']>;
  ip1?: Maybe<Scalars['Boolean']['output']>;
  ip2?: Maybe<Scalars['Boolean']['output']>;
  ip3?: Maybe<Scalars['Boolean']['output']>;
  ip4?: Maybe<Scalars['Boolean']['output']>;
  ip5?: Maybe<Scalars['Boolean']['output']>;
  ip6?: Maybe<Scalars['Boolean']['output']>;
  ip7?: Maybe<Scalars['Boolean']['output']>;
  ip8?: Maybe<Scalars['Boolean']['output']>;
  ip9?: Maybe<Scalars['Boolean']['output']>;
  ip10?: Maybe<Scalars['Boolean']['output']>;
  ip11?: Maybe<Scalars['Boolean']['output']>;
  ip12?: Maybe<Scalars['Boolean']['output']>;
  ip13?: Maybe<Scalars['Boolean']['output']>;
  ip14?: Maybe<Scalars['Boolean']['output']>;
  ip15?: Maybe<Scalars['Boolean']['output']>;
  ip16?: Maybe<Scalars['Boolean']['output']>;
  ip17?: Maybe<Scalars['Boolean']['output']>;
  ip18?: Maybe<Scalars['Boolean']['output']>;
  ip19?: Maybe<Scalars['Boolean']['output']>;
  ip20?: Maybe<Scalars['Boolean']['output']>;
  ip21?: Maybe<Scalars['Boolean']['output']>;
  ip22?: Maybe<Scalars['Boolean']['output']>;
  ip23?: Maybe<Scalars['Boolean']['output']>;
  ip24?: Maybe<Scalars['Boolean']['output']>;
  ip25?: Maybe<Scalars['Boolean']['output']>;
  ip26?: Maybe<Scalars['Boolean']['output']>;
  ip27?: Maybe<Scalars['Boolean']['output']>;
  ip28?: Maybe<Scalars['Boolean']['output']>;
  ip29?: Maybe<Scalars['Boolean']['output']>;
  ip30?: Maybe<Scalars['Boolean']['output']>;
  p1a?: Maybe<Scalars['Boolean']['output']>;
  p2?: Maybe<Scalars['Boolean']['output']>;
  p3?: Maybe<Scalars['Boolean']['output']>;
  p4?: Maybe<Scalars['Boolean']['output']>;
  p5?: Maybe<Scalars['Boolean']['output']>;
  p6?: Maybe<Scalars['Boolean']['output']>;
  p7?: Maybe<Scalars['Boolean']['output']>;
  p8?: Maybe<Scalars['Boolean']['output']>;
  p9?: Maybe<Scalars['Boolean']['output']>;
  p10?: Maybe<Scalars['Boolean']['output']>;
  p11?: Maybe<Scalars['Boolean']['output']>;
  p12?: Maybe<Scalars['Boolean']['output']>;
  p13?: Maybe<Scalars['Boolean']['output']>;
  p14?: Maybe<Scalars['Boolean']['output']>;
  p15a?: Maybe<Scalars['Boolean']['output']>;
  p16a?: Maybe<Scalars['Boolean']['output']>;
  p17?: Maybe<Scalars['Boolean']['output']>;
  p18?: Maybe<Scalars['Boolean']['output']>;
  p19a?: Maybe<Scalars['Boolean']['output']>;
  p20b?: Maybe<Scalars['Boolean']['output']>;
  yv2?: Maybe<Scalars['Boolean']['output']>;
  yv4?: Maybe<Scalars['Boolean']['output']>;
  yv5?: Maybe<Scalars['Boolean']['output']>;
  yv8?: Maybe<Scalars['Boolean']['output']>;
  yv12?: Maybe<Scalars['Boolean']['output']>;
  yv21a?: Maybe<Scalars['Boolean']['output']>;
  yv23?: Maybe<Scalars['Boolean']['output']>;
  yv26?: Maybe<Scalars['Boolean']['output']>;
  yv28?: Maybe<Scalars['Boolean']['output']>;
  yv29?: Maybe<Scalars['Boolean']['output']>;
  yv32?: Maybe<Scalars['Boolean']['output']>;
  yv33?: Maybe<Scalars['Boolean']['output']>;
  yv34?: Maybe<Scalars['Boolean']['output']>;
  yv37?: Maybe<Scalars['Boolean']['output']>;
  yv38?: Maybe<Scalars['Boolean']['output']>;
};

export type AnswersItemInput = {
  c4?: InputMaybe<Scalars['Boolean']['input']>;
  c7?: InputMaybe<Scalars['Boolean']['input']>;
  c10?: InputMaybe<Scalars['Boolean']['input']>;
  c13?: InputMaybe<Scalars['Boolean']['input']>;
  c14?: InputMaybe<Scalars['Boolean']['input']>;
  c16?: InputMaybe<Scalars['Boolean']['input']>;
  c17?: InputMaybe<Scalars['Boolean']['input']>;
  c19?: InputMaybe<Scalars['Boolean']['input']>;
  c22?: InputMaybe<Scalars['Boolean']['input']>;
  c23?: InputMaybe<Scalars['Boolean']['input']>;
  c24?: InputMaybe<Scalars['Boolean']['input']>;
  c24a?: InputMaybe<Scalars['Boolean']['input']>;
  c25?: InputMaybe<Scalars['Boolean']['input']>;
  dt4?: InputMaybe<Scalars['Boolean']['input']>;
  hi3?: InputMaybe<Scalars['Boolean']['input']>;
  ip1?: InputMaybe<Scalars['Boolean']['input']>;
  ip2?: InputMaybe<Scalars['Boolean']['input']>;
  ip3?: InputMaybe<Scalars['Boolean']['input']>;
  ip4?: InputMaybe<Scalars['Boolean']['input']>;
  ip5?: InputMaybe<Scalars['Boolean']['input']>;
  ip6?: InputMaybe<Scalars['Boolean']['input']>;
  ip7?: InputMaybe<Scalars['Boolean']['input']>;
  ip8?: InputMaybe<Scalars['Boolean']['input']>;
  ip9?: InputMaybe<Scalars['Boolean']['input']>;
  ip10?: InputMaybe<Scalars['Boolean']['input']>;
  ip11?: InputMaybe<Scalars['Boolean']['input']>;
  ip12?: InputMaybe<Scalars['Boolean']['input']>;
  ip13?: InputMaybe<Scalars['Boolean']['input']>;
  ip14?: InputMaybe<Scalars['Boolean']['input']>;
  ip15?: InputMaybe<Scalars['Boolean']['input']>;
  ip16?: InputMaybe<Scalars['Boolean']['input']>;
  ip17?: InputMaybe<Scalars['Boolean']['input']>;
  ip18?: InputMaybe<Scalars['Boolean']['input']>;
  ip19?: InputMaybe<Scalars['Boolean']['input']>;
  ip20?: InputMaybe<Scalars['Boolean']['input']>;
  ip21?: InputMaybe<Scalars['Boolean']['input']>;
  ip22?: InputMaybe<Scalars['Boolean']['input']>;
  ip23?: InputMaybe<Scalars['Boolean']['input']>;
  ip24?: InputMaybe<Scalars['Boolean']['input']>;
  ip25?: InputMaybe<Scalars['Boolean']['input']>;
  ip26?: InputMaybe<Scalars['Boolean']['input']>;
  ip27?: InputMaybe<Scalars['Boolean']['input']>;
  ip28?: InputMaybe<Scalars['Boolean']['input']>;
  ip29?: InputMaybe<Scalars['Boolean']['input']>;
  ip30?: InputMaybe<Scalars['Boolean']['input']>;
  p1a?: InputMaybe<Scalars['Boolean']['input']>;
  p2?: InputMaybe<Scalars['Boolean']['input']>;
  p3?: InputMaybe<Scalars['Boolean']['input']>;
  p4?: InputMaybe<Scalars['Boolean']['input']>;
  p5?: InputMaybe<Scalars['Boolean']['input']>;
  p6?: InputMaybe<Scalars['Boolean']['input']>;
  p7?: InputMaybe<Scalars['Boolean']['input']>;
  p8?: InputMaybe<Scalars['Boolean']['input']>;
  p9?: InputMaybe<Scalars['Boolean']['input']>;
  p10?: InputMaybe<Scalars['Boolean']['input']>;
  p11?: InputMaybe<Scalars['Boolean']['input']>;
  p12?: InputMaybe<Scalars['Boolean']['input']>;
  p13?: InputMaybe<Scalars['Boolean']['input']>;
  p14?: InputMaybe<Scalars['Boolean']['input']>;
  p15a?: InputMaybe<Scalars['Boolean']['input']>;
  p16a?: InputMaybe<Scalars['Boolean']['input']>;
  p17?: InputMaybe<Scalars['Boolean']['input']>;
  p18?: InputMaybe<Scalars['Boolean']['input']>;
  p19a?: InputMaybe<Scalars['Boolean']['input']>;
  p20b?: InputMaybe<Scalars['Boolean']['input']>;
  yv2?: InputMaybe<Scalars['Boolean']['input']>;
  yv4?: InputMaybe<Scalars['Boolean']['input']>;
  yv5?: InputMaybe<Scalars['Boolean']['input']>;
  yv8?: InputMaybe<Scalars['Boolean']['input']>;
  yv12?: InputMaybe<Scalars['Boolean']['input']>;
  yv21a?: InputMaybe<Scalars['Boolean']['input']>;
  yv23?: InputMaybe<Scalars['Boolean']['input']>;
  yv26?: InputMaybe<Scalars['Boolean']['input']>;
  yv28?: InputMaybe<Scalars['Boolean']['input']>;
  yv29?: InputMaybe<Scalars['Boolean']['input']>;
  yv32?: InputMaybe<Scalars['Boolean']['input']>;
  yv33?: InputMaybe<Scalars['Boolean']['input']>;
  yv34?: InputMaybe<Scalars['Boolean']['input']>;
  yv37?: InputMaybe<Scalars['Boolean']['input']>;
  yv38?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Apology = {
  __typename?: 'Apology';
  reason: Scalars['String']['output'];
  supportWorkerId: Scalars['String']['output'];
};

export type ApologyInput = {
  reason: Scalars['String']['input'];
  supportWorkerId: Scalars['String']['input'];
};

export enum ApplicationTrackingSteps {
  ApplicationUploaded = 'ApplicationUploaded',
  AutoWelcomeTextAndEmailSent = 'AutoWelcomeTextAndEmailSent',
  InitialCallArranged = 'InitialCallArranged',
  InitialCallDone = 'InitialCallDone',
  JobOfferAccepted = 'JobOfferAccepted',
  ShadowAgreed = 'ShadowAgreed',
  ShadowSuccess = 'ShadowSuccess',
  WorkshopAttended = 'WorkshopAttended',
  WorkshopInviteSent = 'WorkshopInviteSent'
}

export type AscotExpectedOutcome = {
  __typename?: 'AscotExpectedOutcome';
  accomodationAndComfort?: Maybe<AscotExpectedOutcomeScore>;
  controlOverDailyLife?: Maybe<AscotExpectedOutcomeScore>;
  foodAndDrink?: Maybe<AscotExpectedOutcomeScore>;
  id: Scalars['String']['output'];
  occupation?: Maybe<AscotExpectedOutcomeScore>;
  personalCleanlinessAndComfort?: Maybe<AscotExpectedOutcomeScore>;
  personalSafety?: Maybe<AscotExpectedOutcomeScore>;
  reviewDate: Scalars['Float']['output'];
  reviewId: Scalars['String']['output'];
  socialParticipationAndInvolvement?: Maybe<AscotExpectedOutcomeScore>;
  supportWorkerId: Scalars['String']['output'];
  supportedPersonId: Scalars['String']['output'];
};

export type AscotExpectedOutcomeByIdSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  reviewId?: InputMaybe<Scalars['String']['input']>;
};

export type AscotExpectedOutcomeInput = {
  accomodationAndComfort?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  controlOverDailyLife?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  foodAndDrink?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  personalCleanlinessAndComfort?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  personalSafety?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  reviewDate: Scalars['Float']['input'];
  reviewId: Scalars['String']['input'];
  socialParticipationAndInvolvement?: InputMaybe<AscotExpectedOutcomeScoreInput>;
  supportWorkerId: Scalars['String']['input'];
  supportedPersonId: Scalars['String']['input'];
};

export type AscotExpectedOutcomeListSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type AscotExpectedOutcomeScore = {
  __typename?: 'AscotExpectedOutcomeScore';
  answer: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type AscotExpectedOutcomeScoreInput = {
  answer: Scalars['String']['input'];
  score: Scalars['Float']['input'];
};

export type AscotReview = {
  __typename?: 'AscotReview';
  accomodationAndComfort?: Maybe<AscotReviewScore>;
  agreedActionsAndOutcomes: Scalars['String']['output'];
  agreedActionsToChange: Scalars['String']['output'];
  controlOverDailyLife?: Maybe<AscotReviewScore>;
  dignity?: Maybe<AscotReviewScore>;
  foodAndDrink?: Maybe<AscotReviewScore>;
  id: Scalars['String']['output'];
  infoToUpdate: Scalars['String']['output'];
  intro: Scalars['String']['output'];
  meetingPurpose: Scalars['String']['output'];
  newOutcomes: Scalars['String']['output'];
  nextMeetingAgenda: Scalars['String']['output'];
  occupation?: Maybe<AscotReviewScore>;
  personalCleanlinessAndComfort?: Maybe<AscotReviewScore>;
  personalSafety?: Maybe<AscotReviewScore>;
  progressToAcheivingOutComes: Scalars['String']['output'];
  reviewDate: Scalars['Float']['output'];
  reviewId: Scalars['String']['output'];
  reviewType: Scalars['String']['output'];
  socialParticipationAndInvolvement?: Maybe<AscotReviewScore>;
  supportedPersonId: Scalars['String']['output'];
  whatDoBetter: Scalars['String']['output'];
};

export type AscotReviewByIdSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  reviewId?: InputMaybe<Scalars['String']['input']>;
};

export type AscotReviewDeleteInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  reviewId?: InputMaybe<Scalars['String']['input']>;
};

export type AscotReviewInput = {
  accomodationAndComfort?: InputMaybe<AscotReviewScoreInput>;
  agreedActionsAndOutcomes: Scalars['String']['input'];
  agreedActionsToChange: Scalars['String']['input'];
  controlOverDailyLife?: InputMaybe<AscotReviewScoreInput>;
  dignity?: InputMaybe<AscotReviewScoreInput>;
  foodAndDrink?: InputMaybe<AscotReviewScoreInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  infoToUpdate: Scalars['String']['input'];
  intro: Scalars['String']['input'];
  meetingPurpose: Scalars['String']['input'];
  newOutcomes: Scalars['String']['input'];
  nextMeetingAgenda: Scalars['String']['input'];
  occupation?: InputMaybe<AscotReviewScoreInput>;
  personalCleanlinessAndComfort?: InputMaybe<AscotReviewScoreInput>;
  personalSafety?: InputMaybe<AscotReviewScoreInput>;
  progressToAcheivingOutComes: Scalars['String']['input'];
  reviewDate: Scalars['Float']['input'];
  reviewId: Scalars['String']['input'];
  reviewType: Scalars['String']['input'];
  socialParticipationAndInvolvement?: InputMaybe<AscotReviewScoreInput>;
  supportedPersonId: Scalars['String']['input'];
  whatDoBetter: Scalars['String']['input'];
};

export type AscotReviewListSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type AscotReviewScore = {
  __typename?: 'AscotReviewScore';
  answer: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  score2?: Maybe<Scalars['Float']['output']>;
};

export type AscotReviewScoreInput = {
  answer: Scalars['String']['input'];
  score: Scalars['Float']['input'];
  score2?: InputMaybe<Scalars['Float']['input']>;
};

export type Assessment = {
  __typename?: 'Assessment';
  answers?: Maybe<Array<Maybe<AssessmentAnswer>>>;
  completedAt?: Maybe<Scalars['Float']['output']>;
  completedBySupportWorkerId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Float']['output']>;
  createdBySupportWorkerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<AssessmentQuestion>>>;
  state?: Maybe<Scalars['String']['output']>;
  supportedPersonId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Float']['output']>;
};

export type AssessmentAnswer = {
  __typename?: 'AssessmentAnswer';
  answer?: Maybe<AssessmentAnswerAnswer>;
  assessmentId?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Float']['output']>;
};

export type AssessmentAnswerAnswer = {
  __typename?: 'AssessmentAnswerAnswer';
  choiceId?: Maybe<Scalars['String']['output']>;
  choiceIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type AssessmentAnswerAnswerInput = {
  choiceId?: InputMaybe<Scalars['String']['input']>;
  choiceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AssessmentAnswerInput = {
  answer?: InputMaybe<AssessmentAnswerAnswerInput>;
  assessmentId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Float']['input']>;
};

export type AssessmentByIdSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssessmentInput = {
  answers?: InputMaybe<Array<InputMaybe<AssessmentAnswerInput>>>;
  completedAt?: InputMaybe<Scalars['Float']['input']>;
  completedBySupportWorkerId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Float']['input']>;
  createdBySupportWorkerId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<AssessmentQuestionInput>>>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  supportedPersonId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Float']['input']>;
};

export type AssessmentLayout = {
  __typename?: 'AssessmentLayout';
  label: Scalars['String']['output'];
  questions: Array<Maybe<AssessmentSubSection>>;
  type: Scalars['String']['output'];
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  answerType?: Maybe<QuestionAnswer>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type AssessmentQuestionInput = {
  answerType?: InputMaybe<QuestionAnswerInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AssessmentRisk = {
  __typename?: 'AssessmentRisk';
  mitigation?: Maybe<Scalars['String']['output']>;
  relatedAssessments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  risk?: Maybe<Scalars['String']['output']>;
  riskLevel?: Maybe<Scalars['String']['output']>;
};

export type AssessmentSubSection = {
  __typename?: 'AssessmentSubSection';
  hasNote?: Maybe<Scalars['Boolean']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type AssesssmentListSearch = {
  supportedPersonId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssignTeamSupportWorkerAccountInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type BrowserInput = {
  android?: InputMaybe<Scalars['Boolean']['input']>;
  chrome?: InputMaybe<Scalars['Boolean']['input']>;
  firefox?: InputMaybe<Scalars['Boolean']['input']>;
  ie?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_safari?: InputMaybe<Scalars['Boolean']['input']>;
  mozilla?: InputMaybe<Scalars['Boolean']['input']>;
  opera?: InputMaybe<Scalars['Boolean']['input']>;
  safari?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  webkit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrowserWindowInput = {
  height?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type Buddie = {
  __typename?: 'Buddie';
  avatar?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type BuddieForSupportWorkerSearch = {
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type BuddieGroup = {
  __typename?: 'BuddieGroup';
  buddies?: Maybe<Array<Maybe<Buddie>>>;
  groupId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type BuddieGroupSearch = {
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type BuddieGroupUpdate = {
  buddies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CancelVisitInput = {
  cancelledDateTime?: InputMaybe<Scalars['Float']['input']>;
  isToBeRescheduled?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  periodDateTime?: InputMaybe<Scalars['Float']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
  whoCancelled?: InputMaybe<Scalars['String']['input']>;
};

export type Candidate = {
  __typename?: 'Candidate';
  address?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  canDrive?: Maybe<Scalars['Boolean']['output']>;
  circleId?: Maybe<Scalars['String']['output']>;
  circleName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentStatus?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['Float']['output']>;
  dateImported?: Maybe<Scalars['Float']['output']>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  dob?: Maybe<Scalars['Float']['output']>;
  eligibleToWork?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<CandidateHistory>>>;
  id?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  previousApplications?: Maybe<Array<Maybe<CandidatePreviousApplications>>>;
  price?: Maybe<Scalars['String']['output']>;
  reasonNotHired?: Maybe<Scalars['String']['output']>;
  reasonNotHiredOther?: Maybe<Scalars['String']['output']>;
  roleAppeal?: Maybe<Scalars['String']['output']>;
  scorecard?: Maybe<Array<Maybe<Scorecard>>>;
  shadowFeedback?: Maybe<CandidateShadowFeedback>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  tracking?: Maybe<CandidateTracking>;
  whyYou?: Maybe<Scalars['String']['output']>;
  workshop?: Maybe<CandidateWorkshop>;
  workshopFeedback?: Maybe<CandidateWorkshopFeedback>;
};

export type CandidateForWorkshopSearchInput = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CandidateHistory = {
  __typename?: 'CandidateHistory';
  contactType?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CandidateHistoryInput = {
  contactType?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Float']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type CandidateImportInput = {
  candidates?: InputMaybe<Array<InputMaybe<CreateCandidate>>>;
};

export type CandidatePreviousApplications = {
  __typename?: 'CandidatePreviousApplications';
  address?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  canDrive?: Maybe<Scalars['Boolean']['output']>;
  circleId?: Maybe<Scalars['String']['output']>;
  circleName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentStatus?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['Float']['output']>;
  dateImported?: Maybe<Scalars['Float']['output']>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  dob?: Maybe<Scalars['Float']['output']>;
  eligibleToWork?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<CandidateHistory>>>;
  id?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  reasonNotHired?: Maybe<Scalars['String']['output']>;
  reasonNotHiredOther?: Maybe<Scalars['String']['output']>;
  scorecard?: Maybe<Array<Maybe<Scorecard>>>;
  shadowFeedback?: Maybe<CandidateShadowFeedback>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  tracking?: Maybe<CandidateTracking>;
  workshop?: Maybe<CandidateWorkshop>;
  workshopFeedback?: Maybe<CandidateWorkshopFeedback>;
};

export type CandidateReport = {
  __typename?: 'CandidateReport';
  candidateId?: Maybe<Scalars['String']['output']>;
  candidateName: Scalars['String']['output'];
  data?: Maybe<Scalars['String']['output']>;
  dateUpdated: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  supportWorkerId?: Maybe<Scalars['String']['output']>;
};

export type CandidateReportList = {
  __typename?: 'CandidateReportList';
  candidateId?: Maybe<Scalars['String']['output']>;
  candidateName: Scalars['String']['output'];
  dateUpdated: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  supportWorkerId?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearch = {
  circleName?: InputMaybe<Scalars['String']['input']>;
  currentStatus?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type CandidateShadowFeedback = {
  __typename?: 'CandidateShadowFeedback';
  communication?: Maybe<Scalars['String']['output']>;
  conscientiousness?: Maybe<Scalars['String']['output']>;
  engagementWillingness?: Maybe<Scalars['String']['output']>;
  listening?: Maybe<Scalars['String']['output']>;
  observationDate?: Maybe<Scalars['Float']['output']>;
  other?: Maybe<Scalars['String']['output']>;
  personObserving?: Maybe<Scalars['String']['output']>;
  teamPlayer?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Scalars['String']['output']>;
};

export type CandidateShadowFeedbackInput = {
  communication?: InputMaybe<Scalars['String']['input']>;
  conscientiousness?: InputMaybe<Scalars['String']['input']>;
  engagementWillingness?: InputMaybe<Scalars['String']['input']>;
  listening?: InputMaybe<Scalars['String']['input']>;
  observationDate?: InputMaybe<Scalars['Float']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  personObserving?: InputMaybe<Scalars['String']['input']>;
  teamPlayer?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Scalars['String']['input']>;
};

export enum CandidateStatus {
  Closed = 'Closed',
  Hired = 'Hired',
  Open = 'Open'
}

export enum CandidateTemplates {
  WelcomeEmail = 'WelcomeEmail'
}

export type CandidateTracking = {
  __typename?: 'CandidateTracking';
  applicationUploaded?: Maybe<CandidateTrackingItem>;
  autoWelcomeTextAndEmailSent?: Maybe<CandidateTrackingItem>;
  initialCallArranged?: Maybe<CandidateTrackingItem>;
  initialCallDone?: Maybe<CandidateTrackingItem>;
  jobOfferAccepted?: Maybe<CandidateTrackingItem>;
  shadowAgreed?: Maybe<CandidateTrackingItem>;
  shadowSuccess?: Maybe<CandidateTrackingItem>;
  workshopAttended?: Maybe<CandidateTrackingItem>;
  workshopInviteSent?: Maybe<CandidateTrackingItem>;
};

export type CandidateTrackingInput = {
  applicationUploaded?: InputMaybe<CandidateTrackingItemInput>;
  autoWelcomeTextAndEmailSent?: InputMaybe<CandidateTrackingItemInput>;
  initialCallArranged?: InputMaybe<CandidateTrackingItemInput>;
  initialCallDone?: InputMaybe<CandidateTrackingItemInput>;
  jobOfferAccepted?: InputMaybe<CandidateTrackingItemInput>;
  shadowAgreed?: InputMaybe<CandidateTrackingItemInput>;
  shadowSuccess?: InputMaybe<CandidateTrackingItemInput>;
  workshopAttended?: InputMaybe<CandidateTrackingItemInput>;
  workshopInviteSent?: InputMaybe<CandidateTrackingItemInput>;
};

export type CandidateTrackingItem = {
  __typename?: 'CandidateTrackingItem';
  canManuallyUpdate?: Maybe<Scalars['Boolean']['output']>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  stage?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type CandidateTrackingItemInput = {
  canManuallyUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  dateUpdated?: InputMaybe<Scalars['Float']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CandidateWorkshop = {
  __typename?: 'CandidateWorkshop';
  attended?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  passed?: Maybe<Scalars['Boolean']['output']>;
  shadowWithId?: Maybe<Scalars['String']['output']>;
};

export type CandidateWorkshopFeedback = {
  __typename?: 'CandidateWorkshopFeedback';
  communication?: Maybe<Scalars['String']['output']>;
  empathy?: Maybe<Scalars['String']['output']>;
  observationDate?: Maybe<Scalars['Float']['output']>;
  overallFeedback?: Maybe<Scalars['String']['output']>;
  patience?: Maybe<Scalars['String']['output']>;
  personObserving?: Maybe<Scalars['String']['output']>;
  personability?: Maybe<Scalars['String']['output']>;
  progressShadow?: Maybe<Scalars['String']['output']>;
  teamWork?: Maybe<Scalars['String']['output']>;
};

export type CandidateWorkshopFeedbackInput = {
  communication?: InputMaybe<Scalars['String']['input']>;
  empathy?: InputMaybe<Scalars['String']['input']>;
  observationDate?: InputMaybe<Scalars['Float']['input']>;
  overallFeedback?: InputMaybe<Scalars['String']['input']>;
  patience?: InputMaybe<Scalars['String']['input']>;
  personObserving?: InputMaybe<Scalars['String']['input']>;
  personability?: InputMaybe<Scalars['String']['input']>;
  progressShadow?: InputMaybe<Scalars['String']['input']>;
  teamWork?: InputMaybe<Scalars['String']['input']>;
};

export type CandidateWorkshopInput = {
  attended?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  shadowWithId?: InputMaybe<Scalars['String']['input']>;
};

export type CaregiverInVisitType = {
  __typename?: 'CaregiverInVisitType';
  actual_end_at?: Maybe<Scalars['String']['output']>;
  actual_start_at?: Maybe<Scalars['String']['output']>;
  user?: Maybe<CaregiverType>;
};

export type CaregiverType = {
  __typename?: 'CaregiverType';
  address?: Maybe<Scalars['String']['output']>;
  agency_id?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ChatFile = {
  __typename?: 'ChatFile';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ChatFileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  _id?: Maybe<Scalars['String']['output']>;
  audio?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Float']['output']>;
  file?: Maybe<ChatFile>;
  image?: Maybe<Scalars['String']['output']>;
  pending?: Maybe<Scalars['Boolean']['output']>;
  received?: Maybe<Scalars['Boolean']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  sent?: Maybe<Scalars['Boolean']['output']>;
  system?: Maybe<Scalars['Boolean']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ChatMessageUser>;
  video?: Maybe<Scalars['String']['output']>;
};

export type ChatMessageSearch = {
  roomId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ChatMessageUser = {
  __typename?: 'ChatMessageUser';
  _id?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  active?: Maybe<Scalars['Boolean']['output']>;
  customer?: Maybe<ChatUser>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  members?: Maybe<Array<Maybe<ChatUser>>>;
  teamId?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
};

export type ChatRoomSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ChatUser = {
  __typename?: 'ChatUser';
  avatar?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newMessages?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ChatUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  newMessages?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CheckListQuestion = {
  __typename?: 'CheckListQuestion';
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type CheckListQuestionInput = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type ConfirmationPracticeGroup = {
  __typename?: 'ConfirmationPracticeGroup';
  answers?: Maybe<Array<Maybe<Answer>>>;
  buddies?: Maybe<Array<Maybe<Buddie>>>;
  createdDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isSolo?: Maybe<Scalars['Boolean']['output']>;
  questions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type ConfirmationPracticeGroupInput = {
  buddies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSolo?: InputMaybe<Scalars['Boolean']['input']>;
  questions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationPracticeGroupSearch = {
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationPracticeQuestion = {
  __typename?: 'ConfirmationPracticeQuestion';
  checkList?: Maybe<Array<Maybe<ConfirmationPracticeQuestionCheckListItem>>>;
  createdAt?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ConfirmationPracticeQuestionCheckListItem = {
  __typename?: 'ConfirmationPracticeQuestionCheckListItem';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
};

export type ConfirmationPracticeQuestionCheckListItemInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationPracticeQuestionInput = {
  checkList?: InputMaybe<Array<InputMaybe<ConfirmationPracticeQuestionCheckListItemInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationPracticeQuestionListSearch = {
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationPracticesReport = {
  __typename?: 'ConfirmationPracticesReport';
  createdDateTime?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  focus?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  numberOfShifts?: Maybe<Scalars['Int']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  whyScore?: Maybe<Scalars['String']['output']>;
};

export type ConfirmationPracticesReportSearch = {
  endDate?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
};

export type ContactResponse = {
  __typename?: 'ContactResponse';
  date?: Maybe<Scalars['Float']['output']>;
  message: Scalars['String']['output'];
};

export type ContactUsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  recipientEmail?: InputMaybe<Scalars['String']['input']>;
};

export type ControlInputType = {
  currentInvolvement?: InputMaybe<Scalars['String']['input']>;
  feelingsAboutControl?: InputMaybe<Scalars['String']['input']>;
  strengths?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type ControlType = {
  __typename?: 'ControlType';
  currentInvolvement?: Maybe<Scalars['String']['output']>;
  feelingsAboutControl?: Maybe<Scalars['String']['output']>;
  strengths?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type CoordinatesType = {
  __typename?: 'CoordinatesType';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type CourseReport = {
  __typename?: 'CourseReport';
  courseArea?: Maybe<Scalars['String']['output']>;
  courseName?: Maybe<Scalars['String']['output']>;
  courseType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Float']['output']>;
  dateBooking?: Maybe<Scalars['Float']['output']>;
  dateCompleted?: Maybe<Scalars['Float']['output']>;
  dateExpiry?: Maybe<Scalars['Float']['output']>;
  employeeCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type CourseReportSearch = {
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCandidate = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  canDrive?: InputMaybe<Scalars['Boolean']['input']>;
  currentStatus?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['Float']['input']>;
  eligibleToWork?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobRole?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCandidateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobRole?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChatMessageInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<ChatFileInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  pending?: InputMaybe<Scalars['Boolean']['input']>;
  received?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  sent?: InputMaybe<Scalars['Boolean']['input']>;
  system?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<CreateChatMessageUser>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChatMessageUser = {
  _id?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChatRoomInput = {
  customer?: InputMaybe<ChatUserInput>;
  members?: InputMaybe<Array<InputMaybe<ChatUserInput>>>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  circleId?: InputMaybe<Scalars['String']['input']>;
  circleName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotDealId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFamilyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePresignedUrlInput = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CreatePresignedUrlResponse = {
  __typename?: 'CreatePresignedUrlResponse';
  date?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
};

export type CreateWebsiteCandidateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  car?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobRole?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  roleAppeal?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
  whyYou?: InputMaybe<Scalars['String']['input']>;
  workEligible?: InputMaybe<Scalars['String']['input']>;
  workTimes?: InputMaybe<Scalars['String']['input']>;
  workedInCare?: InputMaybe<Scalars['String']['input']>;
};

export type CrossTeamSearch = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrentStatus {
  Applied = 'Applied',
  ContactedForInitialCall = 'ContactedForInitialCall',
  DidNotAnswer = 'DidNotAnswer',
  DidNotMeetEligibilityCriteria = 'DidNotMeetEligibilityCriteria',
  DidNotShowUp = 'DidNotShowUp',
  FullAppFormSubmitted = 'FullAppFormSubmitted',
  InitialCallArranged = 'InitialCallArranged',
  InitialCallDone = 'InitialCallDone',
  JobOfferAccepted = 'JobOfferAccepted',
  OnHold = 'OnHold',
  ShadowingAgreed = 'ShadowingAgreed',
  SuccessfulShadowing = 'SuccessfulShadowing',
  UnsuccessfulAfterShadowing = 'UnsuccessfulAfterShadowing',
  UnsuccessfulAfterWorkshop = 'UnsuccessfulAfterWorkshop',
  WithdrawnApplication = 'WithdrawnApplication',
  WorkshopAttended = 'WorkshopAttended',
  WorkshopBooked = 'WorkshopBooked'
}

export type Customer = {
  __typename?: 'Customer';
  accessImage?: Maybe<Scalars['String']['output']>;
  accessNotes?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  address: Scalars['String']['output'];
  birdieId?: Maybe<Scalars['String']['output']>;
  circleId: Scalars['String']['output'];
  circleName: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  contactEmailAddress?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Maybe<CustomerContact>>>;
  dateCreated?: Maybe<Scalars['Float']['output']>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  dob?: Maybe<Scalars['Float']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  ethnicity?: Maybe<NhsField>;
  externalId: Scalars['String']['output'];
  familyMembers?: Maybe<Array<Maybe<Family>>>;
  firstName: Scalars['String']['output'];
  firstVisitDate?: Maybe<Scalars['Float']['output']>;
  healthIssues?: Maybe<Array<Maybe<CustomerHealthIssue>>>;
  hubspotDealId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Image>;
  interests?: Maybe<Array<Maybe<CustomerInterest>>>;
  lastName: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lifeEvents?: Maybe<Array<Maybe<CustomerLifeEvent>>>;
  linkWorkers?: Maybe<Array<Maybe<SupportWorker>>>;
  livesWith?: Maybe<Scalars['String']['output']>;
  lng: Scalars['Float']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  nhsNumber?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<CustomerNote>>>;
  phone?: Maybe<Scalars['String']['output']>;
  placeOfBirth?: Maybe<NhsCountryField>;
  postcode: Scalars['String']['output'];
  preferredName?: Maybe<Scalars['String']['output']>;
  profileData?: Maybe<CustomerProfile>;
  religion?: Maybe<NhsField>;
  reviews?: Maybe<Array<Maybe<CustomerReview>>>;
  safeguarding?: Maybe<SafeGuarding>;
  sex?: Maybe<NhsField>;
  teamId: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
  teamPeriod?: Maybe<Array<Maybe<TeamPeriod>>>;
  title?: Maybe<Scalars['String']['output']>;
  wellbeingStatus?: Maybe<Scalars['String']['output']>;
  wellbeingStatuses?: Maybe<Array<Maybe<WellbeingStatus>>>;
};

export type CustomerActivities = {
  __typename?: 'CustomerActivities';
  activities?: Maybe<Array<Maybe<CustomerActivity>>>;
  category: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  personalOutcome: Scalars['String']['output'];
};

export type CustomerActivitiesDeleteInput = {
  customerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type CustomerActivitiesInput = {
  activities?: InputMaybe<Array<InputMaybe<CustomerActivityInput>>>;
  category: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  personalOutcome: Scalars['String']['input'];
};

export type CustomerActivitiesSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerActivity = {
  __typename?: 'CustomerActivity';
  frequency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  repeat?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['Float']['output']>;
  timeOfDay?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  who: Scalars['String']['output'];
};

export type CustomerActivityInput = {
  frequency: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  repeat?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  timeOfDay?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  who: Scalars['String']['input'];
};

export type CustomerByIdSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerContact = {
  __typename?: 'CustomerContact';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  relationship: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CustomerContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  relationship: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CustomerDailyLiving = {
  __typename?: 'CustomerDailyLiving';
  category: Scalars['String']['output'];
  customerDailyLivingGoal?: Maybe<Array<Maybe<CustomerDailyLivingGoal>>>;
  customerId: Scalars['String']['output'];
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  personalOutcome: Scalars['String']['output'];
};

export type CustomerDailyLivingGoal = {
  __typename?: 'CustomerDailyLivingGoal';
  category: Scalars['String']['output'];
  frequency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  repeat?: Maybe<Scalars['Float']['output']>;
  routine: Scalars['String']['output'];
  startDate?: Maybe<Scalars['Float']['output']>;
  supportLevel: Scalars['String']['output'];
  timeOfDay: Scalars['String']['output'];
};

export type CustomerDailyLivingGoalInput = {
  category: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
  id: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  repeat?: InputMaybe<Scalars['Float']['input']>;
  routine: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['Float']['input']>;
  supportLevel: Scalars['String']['input'];
  timeOfDay: Scalars['String']['input'];
};

export type CustomerDailyLivingSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerDailyLivingsSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerEditInput = {
  contactEmailAddress?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<NhsFieldInput>;
  firstVisitDate?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<CustomerImageInput>;
  nameSuffix?: InputMaybe<Scalars['String']['input']>;
  nhsNumber?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<NhsCountryFieldInput>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  religion?: InputMaybe<NhsFieldInput>;
  sex?: InputMaybe<NhsFieldInput>;
  teamId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  wellbeingStatus?: InputMaybe<Scalars['String']['input']>;
  wellbeingStatuses?: InputMaybe<Array<InputMaybe<WellbeingStatusInput>>>;
};

export type CustomerHealthIssue = {
  __typename?: 'CustomerHealthIssue';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
};

export type CustomerHealthIssueInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
};

export type CustomerImageInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerInterest = {
  __typename?: 'CustomerInterest';
  category: Scalars['String']['output'];
  id: Scalars['String']['output'];
  interestName: Scalars['String']['output'];
};

export type CustomerInterestInput = {
  category: Scalars['String']['input'];
  interestName: Scalars['String']['input'];
};

export type CustomerInterestSaveInput = {
  category: Scalars['String']['input'];
  id: Scalars['String']['input'];
  interestName: Scalars['String']['input'];
};

export type CustomerLifeEvent = {
  __typename?: 'CustomerLifeEvent';
  date: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CustomerLifeEventInput = {
  date: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CustomerMedication = {
  __typename?: 'CustomerMedication';
  customerId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dose: Scalars['String']['output'];
  exactTime?: Maybe<Scalars['Float']['output']>;
  frequency: Scalars['String']['output'];
  howToTake: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  repeat?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['Float']['output']>;
  support: Scalars['String']['output'];
  timePeriod?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type CustomerMedicationDeleteInput = {
  customerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type CustomerMedicationInput = {
  customerId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dose: Scalars['String']['input'];
  exactTime?: InputMaybe<Scalars['Float']['input']>;
  frequency: Scalars['String']['input'];
  howToTake: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  repeat?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  support: Scalars['String']['input'];
  timePeriod?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CustomerMedicationSearch = {
  customerId: Scalars['String']['input'];
};

export type CustomerNeedInputType = {
  accomodation?: InputMaybe<AccommodationInputType>;
  control?: InputMaybe<ControlInputType>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  dignity?: InputMaybe<DignityInputType>;
  foodAndDrink?: InputMaybe<FoodAndDrinkInputType>;
  id?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<OccupationInputType>;
  personalCleanliness?: InputMaybe<PersonalCleanlinessInputType>;
  personalSafety?: InputMaybe<PersonalSafetyInputType>;
  socialParticipation?: InputMaybe<SocialParticipationInputType>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerNeedSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerNeedType = {
  __typename?: 'CustomerNeedType';
  accomodation?: Maybe<AccommodationType>;
  control?: Maybe<ControlType>;
  customerId?: Maybe<Scalars['String']['output']>;
  dignity?: Maybe<DignityType>;
  foodAndDrink?: Maybe<FoodAndDrinkType>;
  id?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<OccupationType>;
  personalCleanliness?: Maybe<PersonalCleanlinessType>;
  personalSafety?: Maybe<PersonalSafetyType>;
  socialParticipation?: Maybe<SocialParticipationType>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type CustomerNote = {
  __typename?: 'CustomerNote';
  customerId: Scalars['String']['output'];
  date: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
};

export type CustomerNoteInput = {
  customerId: Scalars['String']['input'];
  date: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  note: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPackage = {
  __typename?: 'CustomerPackage';
  customerId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fundingSource?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<Maybe<CustomerPackagePrice>>>;
  startDate?: Maybe<Scalars['Float']['output']>;
};

export type CustomerPackageDeleteInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPackagePrice = {
  __typename?: 'CustomerPackagePrice';
  dayRate?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['Float']['output']>;
  hourlyValue?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  localAuthorityId?: Maybe<Scalars['String']['output']>;
  localAuthorityName?: Maybe<Scalars['String']['output']>;
  monthlyValue?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  numberOfHours?: Maybe<Scalars['Float']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CustomerPackagePriceSaveInput = {
  dayRate?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['Float']['input']>;
  hourlyValue?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  localAuthorityId?: InputMaybe<Scalars['String']['input']>;
  localAuthorityName?: InputMaybe<Scalars['String']['input']>;
  monthlyValue?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  numberOfHours?: InputMaybe<Scalars['Float']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPackageSaveInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fundingSource?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prices?: InputMaybe<Array<InputMaybe<CustomerPackagePriceSaveInput>>>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
};

export type CustomerPackageSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  priceId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerProfile = {
  __typename?: 'CustomerProfile';
  aboutMe?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  matters?: Maybe<Scalars['String']['output']>;
  support?: Maybe<Scalars['String']['output']>;
};

export type CustomerProfileInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  profileData?: InputMaybe<InputCustomerProfile>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerReview = {
  __typename?: 'CustomerReview';
  date: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
  requestedBy: Scalars['String']['output'];
};

export type CustomerReviewsInput = {
  id: Scalars['String']['input'];
  reviews?: InputMaybe<Array<InputMaybe<InputCustomerReview>>>;
  teamId: Scalars['String']['input'];
};

export type CustomerSearch = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUpdate = {
  id: Scalars['String']['input'];
  linkWorkers: Array<InputMaybe<Scalars['String']['input']>>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerVisitsInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  customerId: Scalars['String']['input'];
  endDate: Scalars['Float']['input'];
  startDate: Scalars['Float']['input'];
  teamId: Scalars['String']['input'];
};

export type DailyLivingGoalPlan = {
  __typename?: 'DailyLivingGoalPlan';
  category: Scalars['String']['output'];
  completed?: Maybe<Scalars['Boolean']['output']>;
  customerDailyLivingGoalId: Scalars['String']['output'];
  frequency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  note?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  repeat?: Maybe<Scalars['Float']['output']>;
  routine: Scalars['String']['output'];
  startDate: Scalars['Float']['output'];
  supportLevel: Scalars['String']['output'];
  timeOfDay: Scalars['String']['output'];
};

export type DailyLivingGoalPlanInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  customerDailyLivingGoalId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  note?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repeat?: InputMaybe<Scalars['Float']['input']>;
  routine?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  supportLevel?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Scalars['String']['input']>;
};

export type DailyLivingPlan = {
  __typename?: 'DailyLivingPlan';
  category: Scalars['String']['output'];
  customerDailyLivingId: Scalars['String']['output'];
  dailyLivingGoals?: Maybe<Array<Maybe<DailyLivingGoalPlan>>>;
  id: Scalars['String']['output'];
  personalOutcome: Scalars['String']['output'];
};

export type DailyLivingPlanInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  customerDailyLivingId?: InputMaybe<Scalars['String']['input']>;
  dailyLivingGoals?: InputMaybe<Array<InputMaybe<DailyLivingGoalPlanInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  personalOutcome?: InputMaybe<Scalars['String']['input']>;
};

export type DailyLivingRoutine = {
  __typename?: 'DailyLivingRoutine';
  category: Scalars['String']['output'];
  id: Scalars['String']['output'];
  outcomeCategory: Scalars['String']['output'];
  routine: Scalars['String']['output'];
};

export type DeleteActivityFromShiftInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAgreedVisitInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteChatRoomInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteCustomerDailyLivingInput = {
  customerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type DeleteInvoiceInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  payrollStartDate?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteMileageReportingHourInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteMultipleAgreedVisitInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeveloperInput = {
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceInput = {
  family?: InputMaybe<Scalars['String']['input']>;
  major?: InputMaybe<Scalars['String']['input']>;
  minor?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<Scalars['String']['input']>;
};

export type DignityInputType = {
  compromisedAndRespect?: InputMaybe<Scalars['String']['input']>;
  interactionsAndRespect?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type DignityType = {
  __typename?: 'DignityType';
  compromisedAndRespect?: Maybe<Scalars['String']['output']>;
  interactionsAndRespect?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type DisableMemberChatRoomInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type Document = {
  __typename?: 'Document';
  dateCreated?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  ownerType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EditAgreedShiftInput = {
  agreedRotaId: Scalars['String']['input'];
  agreedShiftRunId: Scalars['String']['input'];
  day: Scalars['Int']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  ownerId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type EditAgreedShiftRunInput = {
  agreedRotaId: Scalars['String']['input'];
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

export type EditAgreedVisitInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type EditChatRoomInput = {
  members?: InputMaybe<Array<InputMaybe<ChatUserInput>>>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
};

export type Expense = {
  __typename?: 'Expense';
  comment?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employeeFullName?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  payrollEndDate?: Maybe<Scalars['Float']['output']>;
  payrollStartDate?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
};

export type ExpenseSearchInput = {
  period?: InputMaybe<Scalars['Float']['input']>;
};

export type Family = {
  __typename?: 'Family';
  cognito?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type FamilyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type FamilyMemberCustomersSearch = {
  familyMemberId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type FamilyMembersSearch = {
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type FamilySearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type FoodAndDrinkInputType = {
  challengesToRoutine?: InputMaybe<Scalars['String']['input']>;
  eatingHabitsAndPreferences?: InputMaybe<Scalars['String']['input']>;
  strengths?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type FoodAndDrinkType = {
  __typename?: 'FoodAndDrinkType';
  challengesToRoutine?: Maybe<Scalars['String']['output']>;
  eatingHabitsAndPreferences?: Maybe<Scalars['String']['output']>;
  strengths?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type Form = {
  __typename?: 'Form';
  circleId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Float']['output'];
  dateTimeOfObservation: Scalars['Float']['output'];
  formType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  incident?: Maybe<Incident>;
  medicalObvs?: Maybe<MedicalObvs>;
  observingPersonId: Scalars['String']['output'];
  supportWorkerId: Scalars['String']['output'];
  teamId?: Maybe<Scalars['String']['output']>;
};

export type FormInput = {
  dateTimeOfObservation: Scalars['Float']['input'];
  formType: Scalars['String']['input'];
  incident?: InputMaybe<IncidentInput>;
  medicalObvs?: InputMaybe<MedicalObvsInput>;
  observingPersonId: Scalars['String']['input'];
  supportWorkerId: Scalars['String']['input'];
};

export type FormListSearch = {
  circleId?: InputMaybe<Scalars['String']['input']>;
  formType?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type GetAnswerSearch = {
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type GetCandidateSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GetDayShiftsForTeamSearch = {
  rotaId?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  teamIdToMoveTo?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type GetVisitsForCustomerByDateRangeSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type HubSpotCustomer = {
  __typename?: 'HubSpotCustomer';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  createDateTime?: Maybe<Scalars['Float']['output']>;
  deal?: Maybe<HubSpotCustomerDeal>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  mobilephone?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  relationshipToClient?: Maybe<Scalars['String']['output']>;
  teamhub?: Maybe<HubSpotTeamHubAccount>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type HubSpotCustomerDeal = {
  __typename?: 'HubSpotCustomerDeal';
  createdDateTime?: Maybe<Scalars['Float']['output']>;
  dealstage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  signedTerms?: Maybe<Scalars['Boolean']['output']>;
  team?: Maybe<Scalars['String']['output']>;
};

export type HubSpotSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type HubSpotTeamHubAccount = {
  __typename?: 'HubSpotTeamHubAccount';
  id?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ImageInput = {
  contentType: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type ImageSearch = {
  id: Scalars['String']['input'];
};

export type Incident = {
  __typename?: 'Incident';
  address?: Maybe<Scalars['String']['output']>;
  descriptionOfAccident?: Maybe<Scalars['String']['output']>;
  incidentNumber?: Maybe<Scalars['String']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  resultingAction?: Maybe<Scalars['String']['output']>;
};

export type IncidentInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  descriptionOfAccident?: InputMaybe<Scalars['String']['input']>;
  incidentNumber?: InputMaybe<Scalars['String']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  resultingAction?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type InputCustomerProfile = {
  aboutMe?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  matters?: InputMaybe<Scalars['String']['input']>;
  support?: InputMaybe<Scalars['String']['input']>;
};

export type InputCustomerReview = {
  date: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  note: Scalars['String']['input'];
  requestedBy: Scalars['String']['input'];
};

export type InputPermissions = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  developer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputSupportWorkerProfile = {
  aboutMe?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  matters?: InputMaybe<Scalars['String']['input']>;
  support?: InputMaybe<Scalars['String']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  address?: Maybe<Scalars['String']['output']>;
  cancelledVisits?: Maybe<InvoiceCsvItem>;
  care?: Maybe<InvoiceCsvItem>;
  careItems?: Maybe<Array<Maybe<InvoiceItem>>>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerPackage?: Maybe<CustomerPackage>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['Float']['output']>;
  expenseItems?: Maybe<Array<Maybe<InvoiceItemExpense>>>;
  expenses?: Maybe<InvoiceCsvItem>;
  id?: Maybe<Scalars['String']['output']>;
  indexKey?: Maybe<Scalars['Float']['output']>;
  invoiceDate?: Maybe<Scalars['Float']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceType?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<InvoiceCsvItem>;
  mileageItems?: Maybe<Array<Maybe<InvoiceItem>>>;
  name?: Maybe<Scalars['String']['output']>;
  oneOffVisits?: Maybe<InvoiceCsvItem>;
  payrollEndDate?: Maybe<Scalars['Float']['output']>;
  payrollStartDate?: Maybe<Scalars['Float']['output']>;
  proRata?: Maybe<InvoiceCsvItem>;
  proRataDescription?: Maybe<Scalars['String']['output']>;
  proRataRate?: Maybe<Scalars['String']['output']>;
  proRataType?: Maybe<ProRataType>;
  teamId?: Maybe<Scalars['String']['output']>;
  thrive?: Maybe<InvoiceCsvItem>;
  thriveBookingItems?: Maybe<Array<Maybe<InvoiceItemThriveBooking>>>;
  thriveVat?: Maybe<InvoiceCsvItem>;
  vat?: Maybe<InvoiceCsvItem>;
};

export type InvoiceCsvItem = {
  __typename?: 'InvoiceCsvItem';
  ContactName?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Discount?: Maybe<Scalars['String']['output']>;
  DueDate?: Maybe<Scalars['String']['output']>;
  EmailAddress?: Maybe<Scalars['String']['output']>;
  InvoiceDate?: Maybe<Scalars['String']['output']>;
  InvoiceNumber?: Maybe<Scalars['String']['output']>;
  POAddressLine1?: Maybe<Scalars['String']['output']>;
  POAddressLine2?: Maybe<Scalars['String']['output']>;
  POAddressLine3?: Maybe<Scalars['String']['output']>;
  POAddressLine4?: Maybe<Scalars['String']['output']>;
  POCity?: Maybe<Scalars['String']['output']>;
  POCountry?: Maybe<Scalars['String']['output']>;
  POPostalCode?: Maybe<Scalars['String']['output']>;
  PORegion?: Maybe<Scalars['String']['output']>;
  Quantity?: Maybe<Scalars['String']['output']>;
  Reference?: Maybe<Scalars['String']['output']>;
  TaxAmount?: Maybe<Scalars['String']['output']>;
  Total?: Maybe<Scalars['String']['output']>;
  TrackingName1?: Maybe<Scalars['String']['output']>;
  TrackingOption1?: Maybe<Scalars['String']['output']>;
  UnitAmount?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCsvItemInput = {
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Discount?: InputMaybe<Scalars['String']['input']>;
  DueDate?: InputMaybe<Scalars['String']['input']>;
  EmailAddress?: InputMaybe<Scalars['String']['input']>;
  InvoiceDate?: InputMaybe<Scalars['String']['input']>;
  InvoiceNumber?: InputMaybe<Scalars['String']['input']>;
  POAddressLine1?: InputMaybe<Scalars['String']['input']>;
  POAddressLine2?: InputMaybe<Scalars['String']['input']>;
  POAddressLine3?: InputMaybe<Scalars['String']['input']>;
  POAddressLine4?: InputMaybe<Scalars['String']['input']>;
  POCity?: InputMaybe<Scalars['String']['input']>;
  POCountry?: InputMaybe<Scalars['String']['input']>;
  POPostalCode?: InputMaybe<Scalars['String']['input']>;
  PORegion?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['String']['input']>;
  Reference?: InputMaybe<Scalars['String']['input']>;
  TaxAmount?: InputMaybe<Scalars['String']['input']>;
  Total?: InputMaybe<Scalars['String']['input']>;
  TrackingName1?: InputMaybe<Scalars['String']['input']>;
  TrackingOption1?: InputMaybe<Scalars['String']['input']>;
  UnitAmount?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceExpenseInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  employeeFullName?: InputMaybe<Scalars['String']['input']>;
  expenseDate?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  included?: InputMaybe<Scalars['Boolean']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  cancelledDateTime?: Maybe<Scalars['Float']['output']>;
  endDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  travelWithCustomerDestination?: Maybe<Scalars['String']['output']>;
  travelWithCustomerMileage?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  whoCancelled?: Maybe<Scalars['String']['output']>;
};

export type InvoiceItemExpense = {
  __typename?: 'InvoiceItemExpense';
  comment?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employeeFullName?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceItemInput = {
  cancelledDateTime?: InputMaybe<Scalars['Float']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  included?: InputMaybe<Scalars['Boolean']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  travelWithCustomerDestination?: InputMaybe<Scalars['String']['input']>;
  travelWithCustomerMileage?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  whoCancelled?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceItemThriveBooking = {
  __typename?: 'InvoiceItemThriveBooking';
  customerName?: Maybe<Scalars['String']['output']>;
  endDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  partnerName?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceItemThriveBookingInput = {
  customerName?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  included?: InputMaybe<Scalars['Boolean']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export enum InvoiceType {
  Email = 'Email',
  None = 'None',
  Post = 'Post'
}

export type LocalAuthority = {
  __typename?: 'LocalAuthority';
  clientNumber?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LoginInput = {
  loginToken?: InputMaybe<Scalars['String']['input']>;
  loginTokenCreateDate?: InputMaybe<Scalars['String']['input']>;
  loginTokenSecret?: InputMaybe<Scalars['String']['input']>;
};

export type ManageCustomerAccountInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  newTeamId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ManageSupportWorkerAccountInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MarketingInfo = {
  __typename?: 'MarketingInfo';
  adId?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  queryString?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  returningUser?: Maybe<Scalars['Boolean']['output']>;
};

export type MarketingInfoInput = {
  adId?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  returningUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MedicalObvs = {
  __typename?: 'MedicalObvs';
  anyOtherNotes?: Maybe<Scalars['String']['output']>;
  competencyChecklist: Array<Maybe<CheckListQuestion>>;
  digitalSignatureGiven?: Maybe<Scalars['Boolean']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MedicalObvsInput = {
  anyOtherNotes?: InputMaybe<Scalars['String']['input']>;
  competencyChecklist: Array<InputMaybe<CheckListQuestionInput>>;
  digitalSignatureGiven?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type Medication = {
  __typename?: 'Medication';
  name?: Maybe<Scalars['String']['output']>;
};

export type MedicationEventsType = {
  __typename?: 'MedicationEventsType';
  dose_size?: Maybe<Scalars['String']['output']>;
  medical_product_id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
};

export type MedicationPlan = {
  __typename?: 'MedicationPlan';
  customerMedicationId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dose: Scalars['String']['output'];
  exactTime?: Maybe<Scalars['Float']['output']>;
  frequency: Scalars['String']['output'];
  howToTake: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notTakenReason?: Maybe<Scalars['String']['output']>;
  repeat?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  support: Scalars['String']['output'];
  timePeriod?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type MedicationPlanInput = {
  customerMedicationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dose?: InputMaybe<Scalars['String']['input']>;
  exactTime?: InputMaybe<Scalars['Float']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  howToTake?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notTakenReason?: InputMaybe<Scalars['String']['input']>;
  repeat?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  support?: InputMaybe<Scalars['String']['input']>;
  timePeriod?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MedicationProductType = {
  __typename?: 'MedicationProductType';
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type MedicationTaskInstancesType = {
  __typename?: 'MedicationTaskInstancesType';
  events?: Maybe<Array<Maybe<MedicationEventsType>>>;
  product?: Maybe<MedicationProductType>;
};

export enum MessageType {
  Email = 'Email',
  Sms = 'SMS'
}

export type MileageReportingHours = {
  __typename?: 'MileageReportingHours';
  date?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Float']['output']>;
  mileageDestination?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MoveVisitToShiftInput = {
  currentRotaId?: InputMaybe<Scalars['String']['input']>;
  currentTeamId?: InputMaybe<Scalars['String']['input']>;
  currentVisitId?: InputMaybe<Scalars['String']['input']>;
  newRotaId?: InputMaybe<Scalars['String']['input']>;
  newShiftId?: InputMaybe<Scalars['String']['input']>;
  newTeamId?: InputMaybe<Scalars['String']['input']>;
  shiftStartDateTime?: InputMaybe<Scalars['Float']['input']>;
  shiftType?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ActivateAccountForCustomer: SaveResponse;
  ActivateAccountForWorker: SaveResponse;
  ActivateMemberChatRoom: SaveResponse;
  AddActivityToShift: SaveResponse;
  AddCustomerInterest: SaveResponse;
  AddImage?: Maybe<Image>;
  AddMileageReportingHour: SaveResponse;
  AddVisitToShift: SaveResponse;
  AssignSupportWorkerSurveyReport: SaveResponse;
  CancelVisit: Response;
  ChangeShiftTime: SaveResponse;
  ChangeShiftType: SaveResponse;
  ContactUs: ContactResponse;
  CreateAscotExpectedOutcome: SaveResponse;
  CreateAscotReview: SaveResponse;
  CreateBirdieAccountForCustomer: SaveResponse;
  CreateBirdieAccountForWorker: SaveResponse;
  CreateCandidate: Response;
  CreateChatMessage: ChatMessage;
  CreateChatRoom: SaveResponse;
  CreateCognitoAccountForWorker: SaveResponse;
  CreateFamilyAccounts: SaveResponse;
  CreateInvoice: SaveResponse;
  CreateInvoiceForAll: SaveResponse;
  CreatePresignedUrl: CreatePresignedUrlResponse;
  CreateSupportedPerson: SaveResponse;
  CreateWebsiteCandidate: PublicResponse;
  DeleteActivityFromShift: Response;
  DeleteAgreedVisit: Response;
  DeleteAscotReview: SaveResponse;
  DeleteChatRoom: SaveResponse;
  DeleteCustomerActivities: SaveResponse;
  DeleteCustomerDailyLiving: SaveResponse;
  DeleteCustomerMedication: SaveResponse;
  DeleteCustomerPackage: SaveResponse;
  DeleteDeveloperData: Response;
  DeleteInvoice?: Maybe<Response>;
  DeleteMileageReportingHour: SaveResponse;
  DeleteMultipleAgreedVisit: Response;
  DeleteRota: Response;
  DisableMemberFromChatRoom: SaveResponse;
  EditAgreedShift: SaveResponse;
  EditAgreedShiftRun: SaveResponse;
  EditAgreedVisit: Response;
  EditChatRoom: SaveResponse;
  EditCustomer: SaveResponse;
  EditCustomerNote: SaveResponse;
  GenerateReport: Response;
  ImportCandidates: Response;
  MoveVisitToShift: Response;
  PublishAgreedRota: Response;
  PublishCandidateToHrPartner: SaveResponse;
  PublishTeam: SaveResponse;
  ReadChatMessages: SaveResponse;
  RefreshSWCourseReports: SaveResponse;
  RemoveAgreedRota: Response;
  RemoveAgreedShift: Response;
  RemoveAgreedShiftRun: Response;
  RemoveDocument: SaveResponse;
  RemoveNotifications: Response;
  RemovePlaceholderVisit: Response;
  RemoveTemplate: SaveResponse;
  RevertMoveVisit: Response;
  SaveAgreedRota: SaveResponse;
  SaveAgreedShift: SaveResponse;
  SaveAgreedShiftRun: SaveResponse;
  SaveAgreedvisit: SaveResponse;
  SaveAnswer: SaveResponse;
  SaveAssessment?: Maybe<SaveResponse>;
  SaveBuddieGroup: SaveResponse;
  SaveCandidate: Response;
  SaveConfirmationPracticeQuestion?: Maybe<SaveResponse>;
  SaveConfirmationPracticesGroup: SaveResponse;
  SaveCustomerActivities: SaveResponse;
  SaveCustomerContact: SaveResponse;
  SaveCustomerDailyLiving: SaveResponse;
  SaveCustomerHealthIssue: SaveResponse;
  SaveCustomerHomeAccess: SaveResponse;
  SaveCustomerInterests: SaveResponse;
  SaveCustomerLifeEvent: SaveResponse;
  SaveCustomerLinkWorkers: SaveResponse;
  SaveCustomerMedication: SaveResponse;
  SaveCustomerNeed: SaveResponse;
  SaveCustomerNote: SaveResponse;
  SaveCustomerPackage: SaveResponse;
  SaveCustomerProfile: SaveResponse;
  SaveCustomerReviews: SaveResponse;
  SaveCustomerSafeGuarding: SaveResponse;
  SaveDocument: SaveResponse;
  SaveExpenses: Response;
  SaveFamilyMember: SaveResponse;
  SaveForm: SaveResponse;
  SaveInvoice: SaveResponse;
  SaveMultipleAgreedVisit: Response;
  SavePlaceholderVisit: SaveResponse;
  SaveReportingHours: SaveResponse;
  SaveRisk?: Maybe<SaveResponse>;
  SaveRota: Response;
  SaveShift: SaveResponse;
  SaveSupportWorkerChatToken: SaveResponse;
  SaveSupportWorkerProfile: SaveResponse;
  SaveTeam: SaveResponse;
  SaveTeamMeeting: SaveResponse;
  SaveTemplate: SaveResponse;
  SaveVisitImages: SaveResponse;
  SaveVisitIncident?: Maybe<SaveResponse>;
  SaveVisitNotes: SaveResponse;
  SaveVisitPlanResult: SaveResponse;
  SaveWorkshop: SaveResponse;
  SendCandidateMessages: Response;
  SendSms: Response;
  SendWorkshopEmail: SaveResponse;
  SubmitReportingHour: Response;
  SyncReportingHour: Response;
  TeamChangeForCustomer: SaveResponse;
  TeamChangeForWorker: SaveResponse;
  TerminateAccountForCustomer: SaveResponse;
  TerminateAccountForWorker: SaveResponse;
  UpdateActivity: Response;
  UpdateAgreedVisits: Response;
  UpdateAscotExpectedOutcome: SaveResponse;
  UpdateAscotReview: SaveResponse;
  UpdateFamilyMember: SaveResponse;
  UpdateNotification: Response;
  UpdateReportingHour: SaveResponse;
  UpdateShift: SaveResponse;
  UpdateShiftRun: Response;
  UpdateTeamMeeting: SaveResponse;
  UpdateVisitProgress: SaveResponse;
};


export type MutationActivateAccountForCustomerArgs = {
  input?: InputMaybe<ManageCustomerAccountInput>;
};


export type MutationActivateAccountForWorkerArgs = {
  input?: InputMaybe<ManageSupportWorkerAccountInput>;
};


export type MutationActivateMemberChatRoomArgs = {
  input?: InputMaybe<ActivateMemberChatRoomInput>;
};


export type MutationAddActivityToShiftArgs = {
  input?: InputMaybe<AddActivityToShiftInput>;
};


export type MutationAddCustomerInterestArgs = {
  input?: InputMaybe<CustomerInterestInput>;
};


export type MutationAddImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type MutationAddMileageReportingHourArgs = {
  input?: InputMaybe<AddMileageReportingHourInput>;
};


export type MutationAddVisitToShiftArgs = {
  input?: InputMaybe<AddVisitToShift>;
};


export type MutationAssignSupportWorkerSurveyReportArgs = {
  input?: InputMaybe<SupportWorkerSurveyReportInput>;
};


export type MutationCancelVisitArgs = {
  input?: InputMaybe<CancelVisitInput>;
};


export type MutationChangeShiftTimeArgs = {
  input?: InputMaybe<ShiftRunChangeTimeInput>;
};


export type MutationChangeShiftTypeArgs = {
  input?: InputMaybe<ShiftRunChangeTypeInput>;
};


export type MutationContactUsArgs = {
  input?: InputMaybe<ContactUsInput>;
};


export type MutationCreateAscotExpectedOutcomeArgs = {
  input?: InputMaybe<AscotExpectedOutcomeInput>;
};


export type MutationCreateAscotReviewArgs = {
  input?: InputMaybe<AscotReviewInput>;
};


export type MutationCreateBirdieAccountForCustomerArgs = {
  input?: InputMaybe<ManageCustomerAccountInput>;
};


export type MutationCreateBirdieAccountForWorkerArgs = {
  input?: InputMaybe<ManageSupportWorkerAccountInput>;
};


export type MutationCreateCandidateArgs = {
  input?: InputMaybe<CreateCandidateInput>;
};


export type MutationCreateChatMessageArgs = {
  input?: InputMaybe<CreateChatMessageInput>;
};


export type MutationCreateChatRoomArgs = {
  input?: InputMaybe<CreateChatRoomInput>;
};


export type MutationCreateCognitoAccountForWorkerArgs = {
  input?: InputMaybe<ManageSupportWorkerAccountInput>;
};


export type MutationCreateFamilyAccountsArgs = {
  input?: InputMaybe<CreateFamilyInput>;
};


export type MutationCreateInvoiceArgs = {
  input?: InputMaybe<SaveInvoiceInput>;
};


export type MutationCreateInvoiceForAllArgs = {
  input?: InputMaybe<SaveInvoiceInput>;
};


export type MutationCreatePresignedUrlArgs = {
  input?: InputMaybe<CreatePresignedUrlInput>;
};


export type MutationCreateSupportedPersonArgs = {
  input?: InputMaybe<CreateCustomerInput>;
};


export type MutationCreateWebsiteCandidateArgs = {
  input?: InputMaybe<CreateWebsiteCandidateInput>;
};


export type MutationDeleteActivityFromShiftArgs = {
  input?: InputMaybe<DeleteActivityFromShiftInput>;
};


export type MutationDeleteAgreedVisitArgs = {
  input?: InputMaybe<DeleteAgreedVisitInput>;
};


export type MutationDeleteAscotReviewArgs = {
  input?: InputMaybe<AscotReviewDeleteInput>;
};


export type MutationDeleteChatRoomArgs = {
  input?: InputMaybe<DeleteChatRoomInput>;
};


export type MutationDeleteCustomerActivitiesArgs = {
  input?: InputMaybe<CustomerActivitiesDeleteInput>;
};


export type MutationDeleteCustomerDailyLivingArgs = {
  input?: InputMaybe<DeleteCustomerDailyLivingInput>;
};


export type MutationDeleteCustomerMedicationArgs = {
  input?: InputMaybe<CustomerMedicationDeleteInput>;
};


export type MutationDeleteCustomerPackageArgs = {
  input?: InputMaybe<CustomerPackageDeleteInput>;
};


export type MutationDeleteDeveloperDataArgs = {
  input?: InputMaybe<DeveloperInput>;
};


export type MutationDeleteInvoiceArgs = {
  input?: InputMaybe<DeleteInvoiceInput>;
};


export type MutationDeleteMileageReportingHourArgs = {
  input?: InputMaybe<DeleteMileageReportingHourInput>;
};


export type MutationDeleteMultipleAgreedVisitArgs = {
  input?: InputMaybe<DeleteMultipleAgreedVisitInput>;
};


export type MutationDeleteRotaArgs = {
  input?: InputMaybe<RotaDelete>;
};


export type MutationDisableMemberFromChatRoomArgs = {
  input?: InputMaybe<DisableMemberChatRoomInput>;
};


export type MutationEditAgreedShiftArgs = {
  input?: InputMaybe<EditAgreedShiftInput>;
};


export type MutationEditAgreedShiftRunArgs = {
  input?: InputMaybe<EditAgreedShiftRunInput>;
};


export type MutationEditAgreedVisitArgs = {
  input?: InputMaybe<EditAgreedVisitInput>;
};


export type MutationEditChatRoomArgs = {
  input?: InputMaybe<EditChatRoomInput>;
};


export type MutationEditCustomerArgs = {
  input?: InputMaybe<CustomerEditInput>;
};


export type MutationEditCustomerNoteArgs = {
  input?: InputMaybe<CustomerNoteInput>;
};


export type MutationGenerateReportArgs = {
  input?: InputMaybe<ReportSearch>;
};


export type MutationImportCandidatesArgs = {
  input?: InputMaybe<CandidateImportInput>;
};


export type MutationMoveVisitToShiftArgs = {
  input?: InputMaybe<MoveVisitToShiftInput>;
};


export type MutationPublishAgreedRotaArgs = {
  input?: InputMaybe<PublishAgreedRotaInput>;
};


export type MutationPublishCandidateToHrPartnerArgs = {
  input?: InputMaybe<PublishCandidateInput>;
};


export type MutationPublishTeamArgs = {
  input?: InputMaybe<PublishTeamInput>;
};


export type MutationReadChatMessagesArgs = {
  input?: InputMaybe<ReadChatMessagesInput>;
};


export type MutationRefreshSwCourseReportsArgs = {
  input?: InputMaybe<RefreshSwCourseReportInput>;
};


export type MutationRemoveAgreedRotaArgs = {
  input?: InputMaybe<RemoveAgreedRotaInput>;
};


export type MutationRemoveAgreedShiftArgs = {
  input?: InputMaybe<RemoveAgreedShiftInput>;
};


export type MutationRemoveAgreedShiftRunArgs = {
  input?: InputMaybe<RemoveAgreedShiftRunInput>;
};


export type MutationRemoveDocumentArgs = {
  input?: InputMaybe<RemoveDocumentInput>;
};


export type MutationRemoveNotificationsArgs = {
  input?: InputMaybe<RemoveNotificationsInput>;
};


export type MutationRemovePlaceholderVisitArgs = {
  input?: InputMaybe<RemovePlaceholderVisitInput>;
};


export type MutationRemoveTemplateArgs = {
  input?: InputMaybe<RemoveTemplateInput>;
};


export type MutationRevertMoveVisitArgs = {
  input?: InputMaybe<RevertVisit>;
};


export type MutationSaveAgreedRotaArgs = {
  input?: InputMaybe<AgreedRotaInput>;
};


export type MutationSaveAgreedShiftArgs = {
  input?: InputMaybe<AddAgreedShiftInput>;
};


export type MutationSaveAgreedShiftRunArgs = {
  input?: InputMaybe<AddAgreedShiftRunInput>;
};


export type MutationSaveAgreedvisitArgs = {
  input?: InputMaybe<AddAgreedVisitInput>;
};


export type MutationSaveAnswerArgs = {
  input?: InputMaybe<AnswerInput>;
};


export type MutationSaveAssessmentArgs = {
  input?: InputMaybe<AssessmentInput>;
};


export type MutationSaveBuddieGroupArgs = {
  input?: InputMaybe<BuddieGroupUpdate>;
};


export type MutationSaveCandidateArgs = {
  input?: InputMaybe<SaveCandidateInput>;
};


export type MutationSaveConfirmationPracticeQuestionArgs = {
  input?: InputMaybe<ConfirmationPracticeQuestionInput>;
};


export type MutationSaveConfirmationPracticesGroupArgs = {
  input?: InputMaybe<ConfirmationPracticeGroupInput>;
};


export type MutationSaveCustomerActivitiesArgs = {
  input?: InputMaybe<CustomerActivitiesInput>;
};


export type MutationSaveCustomerContactArgs = {
  input?: InputMaybe<SaveCustomerContactInput>;
};


export type MutationSaveCustomerDailyLivingArgs = {
  input?: InputMaybe<SaveCustomerDailyLivingInput>;
};


export type MutationSaveCustomerHealthIssueArgs = {
  input?: InputMaybe<SaveCustomerHealthIssueInput>;
};


export type MutationSaveCustomerHomeAccessArgs = {
  input?: InputMaybe<SaveCustomerHomeAccessInput>;
};


export type MutationSaveCustomerInterestsArgs = {
  input?: InputMaybe<SaveCustomerInterestsInput>;
};


export type MutationSaveCustomerLifeEventArgs = {
  input?: InputMaybe<SaveCustomerLifeEventInput>;
};


export type MutationSaveCustomerLinkWorkersArgs = {
  input?: InputMaybe<CustomerUpdate>;
};


export type MutationSaveCustomerMedicationArgs = {
  input?: InputMaybe<CustomerMedicationInput>;
};


export type MutationSaveCustomerNeedArgs = {
  input?: InputMaybe<CustomerNeedInputType>;
};


export type MutationSaveCustomerNoteArgs = {
  input?: InputMaybe<CustomerNoteInput>;
};


export type MutationSaveCustomerPackageArgs = {
  input?: InputMaybe<CustomerPackageSaveInput>;
};


export type MutationSaveCustomerProfileArgs = {
  input?: InputMaybe<CustomerProfileInput>;
};


export type MutationSaveCustomerReviewsArgs = {
  input?: InputMaybe<CustomerReviewsInput>;
};


export type MutationSaveCustomerSafeGuardingArgs = {
  input?: InputMaybe<SaveCustomerSafeGuardingInput>;
};


export type MutationSaveDocumentArgs = {
  input?: InputMaybe<SaveDocumentInput>;
};


export type MutationSaveExpensesArgs = {
  input?: InputMaybe<ExpenseSearchInput>;
};


export type MutationSaveFamilyMemberArgs = {
  input?: InputMaybe<FamilyInput>;
};


export type MutationSaveFormArgs = {
  input?: InputMaybe<FormInput>;
};


export type MutationSaveInvoiceArgs = {
  input?: InputMaybe<SaveInvoiceInput>;
};


export type MutationSaveMultipleAgreedVisitArgs = {
  input?: InputMaybe<AddMultipleAgreedVisitInput>;
};


export type MutationSavePlaceholderVisitArgs = {
  input?: InputMaybe<SavePlaceholderVisitInput>;
};


export type MutationSaveReportingHoursArgs = {
  input?: InputMaybe<SaveReportingHoursInput>;
};


export type MutationSaveRiskArgs = {
  input?: InputMaybe<RiskInput>;
};


export type MutationSaveRotaArgs = {
  input?: InputMaybe<RotaInput>;
};


export type MutationSaveShiftArgs = {
  input?: InputMaybe<ShiftInput>;
};


export type MutationSaveSupportWorkerChatTokenArgs = {
  input?: InputMaybe<SupportWorkerChatTokenInput>;
};


export type MutationSaveSupportWorkerProfileArgs = {
  input?: InputMaybe<SupportWorkerProfileInput>;
};


export type MutationSaveTeamArgs = {
  input?: InputMaybe<TeamInput>;
};


export type MutationSaveTeamMeetingArgs = {
  input?: InputMaybe<SaveTeamMeetingInput>;
};


export type MutationSaveTemplateArgs = {
  input?: InputMaybe<SaveTemplateInput>;
};


export type MutationSaveVisitImagesArgs = {
  input?: InputMaybe<SaveVisitImagesInput>;
};


export type MutationSaveVisitIncidentArgs = {
  input?: InputMaybe<VisitIncidentInput>;
};


export type MutationSaveVisitNotesArgs = {
  input?: InputMaybe<SaveVisitNotesInput>;
};


export type MutationSaveVisitPlanResultArgs = {
  input?: InputMaybe<VisitPlanResultInput>;
};


export type MutationSaveWorkshopArgs = {
  input?: InputMaybe<SaveWorkshopInput>;
};


export type MutationSendCandidateMessagesArgs = {
  input?: InputMaybe<SendCandidateMessagesInput>;
};


export type MutationSendSmsArgs = {
  input?: InputMaybe<SendSmsInput>;
};


export type MutationSendWorkshopEmailArgs = {
  input?: InputMaybe<WorkshopSendEmailsInput>;
};


export type MutationSubmitReportingHourArgs = {
  input?: InputMaybe<SubmitReportingHourInput>;
};


export type MutationSyncReportingHourArgs = {
  input?: InputMaybe<SyncReportingHourInput>;
};


export type MutationTeamChangeForCustomerArgs = {
  input?: InputMaybe<ManageCustomerAccountInput>;
};


export type MutationTeamChangeForWorkerArgs = {
  input?: InputMaybe<AssignTeamSupportWorkerAccountInput>;
};


export type MutationTerminateAccountForCustomerArgs = {
  input?: InputMaybe<ManageCustomerAccountInput>;
};


export type MutationTerminateAccountForWorkerArgs = {
  input?: InputMaybe<ManageSupportWorkerAccountInput>;
};


export type MutationUpdateActivityArgs = {
  input?: InputMaybe<UpdateActivityInput>;
};


export type MutationUpdateAgreedVisitsArgs = {
  input?: InputMaybe<UpdateAgreedVisitInput>;
};


export type MutationUpdateAscotExpectedOutcomeArgs = {
  input?: InputMaybe<AscotExpectedOutcomeInput>;
};


export type MutationUpdateAscotReviewArgs = {
  input?: InputMaybe<AscotReviewInput>;
};


export type MutationUpdateFamilyMemberArgs = {
  input?: InputMaybe<UpdateFamilyMemberInput>;
};


export type MutationUpdateNotificationArgs = {
  input?: InputMaybe<NotificationUpdate>;
};


export type MutationUpdateReportingHourArgs = {
  input?: InputMaybe<UpdateReportingHourInput>;
};


export type MutationUpdateShiftArgs = {
  input?: InputMaybe<ShiftUpdate>;
};


export type MutationUpdateShiftRunArgs = {
  input?: InputMaybe<ShiftRunUpdate>;
};


export type MutationUpdateTeamMeetingArgs = {
  input?: InputMaybe<UpdateTeamMeetingInput>;
};


export type MutationUpdateVisitProgressArgs = {
  input?: InputMaybe<UpdateVisitProgressInput>;
};

export type MyShiftSearch = {
  rotaStartDateTime: Scalars['Float']['input'];
  supportWorkerId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type NhsCountryField = {
  __typename?: 'NHSCountryField';
  alpha3Code?: Maybe<Scalars['String']['output']>;
  englishShortName?: Maybe<Scalars['String']['output']>;
};

export type NhsCountryFieldInput = {
  alpha3Code?: InputMaybe<Scalars['String']['input']>;
  englishShortName?: InputMaybe<Scalars['String']['input']>;
};

export type NhsField = {
  __typename?: 'NHSField';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type NhsFieldInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type NewChatMessages = {
  __typename?: 'NewChatMessages';
  newMessages?: Maybe<Scalars['Int']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
};

export type NewChatMessagesSearch = {
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Note = {
  __typename?: 'Note';
  actions?: Maybe<Array<Maybe<NoteActionType>>>;
  note: Scalars['String']['output'];
};

export type NoteActionInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type NoteActionType = {
  __typename?: 'NoteActionType';
  action?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type NoteInput = {
  actions: Array<InputMaybe<NoteActionInput>>;
  note: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  avatar?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type NotificationSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationUpdate = {
  id?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type OccupationInputType = {
  enjoyableActivities?: InputMaybe<Scalars['String']['input']>;
  incidentsAndConcerns?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type OccupationType = {
  __typename?: 'OccupationType';
  enjoyableActivities?: Maybe<Scalars['String']['output']>;
  incidentsAndConcerns?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type OptimisedSearch = {
  includeTBC?: InputMaybe<Scalars['Boolean']['input']>;
  observationDay?: InputMaybe<Scalars['String']['input']>;
  observationWeek?: InputMaybe<Scalars['Float']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export enum PackagePriceFundingSourceEnum {
  LocalAuthority = 'LocalAuthority',
  Nhschc = 'Nhschc',
  SelfFunded = 'SelfFunded'
}

export enum PackagePriceTypeEnum {
  Companionship = 'Companionship',
  EndOfLife = 'EndOfLife',
  HousekeepingCleaning = 'HousekeepingCleaning',
  LongHoursForCareCompanionship = 'LongHoursForCareCompanionship',
  Overnights = 'Overnights',
  PersonalCare = 'PersonalCare',
  ReablementRespite = 'ReablementRespite',
  YoungAdultsWithLd = 'YoungAdultsWithLD'
}

export type PayrollReportSearch = {
  endDateTime: Scalars['Float']['input'];
  startDateTime: Scalars['Float']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type Permissions = {
  __typename?: 'Permissions';
  admin: Scalars['Boolean']['output'];
  developer: Scalars['Boolean']['output'];
};

export type PersonalCleanlinessInputType = {
  challengesToRoutine?: InputMaybe<Scalars['String']['input']>;
  hygieneRoutines?: InputMaybe<Scalars['String']['input']>;
  strengths?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type PersonalCleanlinessType = {
  __typename?: 'PersonalCleanlinessType';
  challengesToRoutine?: Maybe<Scalars['String']['output']>;
  hygieneRoutines?: Maybe<Scalars['String']['output']>;
  strengths?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type PersonalSafetyInputType = {
  homeEnvironmentSaftey?: InputMaybe<Scalars['String']['input']>;
  incidentsAndConcerns?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type PersonalSafetyType = {
  __typename?: 'PersonalSafetyType';
  homeEnvironmentSaftey?: Maybe<Scalars['String']['output']>;
  incidentsAndConcerns?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type PlaceholderVisit = {
  __typename?: 'PlaceholderVisit';
  agreedRotaId?: Maybe<Scalars['String']['output']>;
  agreedShiftRunId?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Float']['output']>;
  supportWorker?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type PlaceholderVisitSearch = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type PrivacySettings = {
  __typename?: 'PrivacySettings';
  nameConsentGiven?: Maybe<Scalars['Boolean']['output']>;
  privacyPolicyAccepted?: Maybe<Scalars['Boolean']['output']>;
};

export type PrivacySettingsInput = {
  nameConsentGiven?: InputMaybe<Scalars['Boolean']['input']>;
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProRataInput = {
  endOfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  future?: InputMaybe<Scalars['Float']['input']>;
  past?: InputMaybe<Scalars['Float']['input']>;
  selectedOption?: InputMaybe<Scalars['String']['input']>;
  startOfAdjustment?: InputMaybe<Scalars['Float']['input']>;
};

export type ProRataType = {
  __typename?: 'ProRataType';
  endOfAdjustment?: Maybe<Scalars['Float']['output']>;
  future?: Maybe<Scalars['Float']['output']>;
  past?: Maybe<Scalars['Float']['output']>;
  selectedOption?: Maybe<Scalars['String']['output']>;
  startOfAdjustment?: Maybe<Scalars['Float']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  canWorkUnsociableHours?: Maybe<Scalars['Boolean']['output']>;
  canWorkWeekends?: Maybe<Scalars['Boolean']['output']>;
  circle?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hasCar?: Maybe<Scalars['Boolean']['output']>;
  hasDrivingLicense?: Maybe<Scalars['Boolean']['output']>;
  isNightDriver?: Maybe<Scalars['Boolean']['output']>;
  isRuralDriver?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInput = {
  canWorkUnsociableHours?: InputMaybe<Scalars['Boolean']['input']>;
  canWorkWeekends?: InputMaybe<Scalars['Boolean']['input']>;
  circle?: InputMaybe<Scalars['String']['input']>;
  hasCar?: InputMaybe<Scalars['Boolean']['input']>;
  hasDrivingLicense?: InputMaybe<Scalars['Boolean']['input']>;
  isNightDriver?: InputMaybe<Scalars['Boolean']['input']>;
  isRuralDriver?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type PublicResponse = {
  __typename?: 'PublicResponse';
  date?: Maybe<Scalars['Float']['output']>;
  message: Scalars['String']['output'];
};

export type PublishAgreedRotaInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type PublishCandidateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type PublishTeamInput = {
  teamId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  GetAgreedRotas?: Maybe<Array<Maybe<AgreedRota>>>;
  GetAgreedShifts?: Maybe<AgreedShiftRunWeeks>;
  GetAgreedVisitsOptimised?: Maybe<AgreedShiftRunWeeksOptimised>;
  GetAllReportingHoursPeriod?: Maybe<Array<Maybe<ReportingHoursPeriod>>>;
  GetAnswerStatus?: Maybe<Array<Scalars['String']['output']>>;
  GetAnswers?: Maybe<Array<Maybe<Answer>>>;
  GetAscotExpectedOutcome?: Maybe<AscotExpectedOutcome>;
  GetAscotExpectedOutcomes?: Maybe<Array<Maybe<AscotExpectedOutcome>>>;
  GetAscotReview?: Maybe<AscotReview>;
  GetAscotReviews?: Maybe<Array<Maybe<AscotReview>>>;
  GetAssessment?: Maybe<Assessment>;
  GetAssessments?: Maybe<Array<Maybe<Assessment>>>;
  GetBirdieVisits?: Maybe<Array<Maybe<VisitType>>>;
  GetBuddieGroups?: Maybe<Array<Maybe<BuddieGroup>>>;
  GetBuddiesForSupportWorker?: Maybe<Array<Maybe<Buddie>>>;
  GetCancelledVisitsReport?: Maybe<Array<Maybe<Shift>>>;
  GetCandidate?: Maybe<Candidate>;
  GetCandidates?: Maybe<Array<Maybe<Candidate>>>;
  GetCandidatesForWorkshop?: Maybe<Array<Maybe<Candidate>>>;
  GetChatMessagesByRoom?: Maybe<Array<Maybe<ChatMessage>>>;
  GetChatRooms?: Maybe<Array<Maybe<ChatRoom>>>;
  GetConfirmationPracticeGroups?: Maybe<Array<Maybe<ConfirmationPracticeGroup>>>;
  GetConfirmationPracticeQuestions?: Maybe<Array<Maybe<ConfirmationPracticeQuestion>>>;
  GetConfirmationPracticesReport?: Maybe<Array<Maybe<ConfirmationPracticesReport>>>;
  GetCourseReportsBySupportWorkerId?: Maybe<Array<Maybe<CourseReport>>>;
  GetCrossTeamVisits?: Maybe<Array<Maybe<Shift>>>;
  GetCustomer?: Maybe<Customer>;
  GetCustomerActivities?: Maybe<Array<Maybe<CustomerActivities>>>;
  GetCustomerContacts?: Maybe<Array<Maybe<CustomerContact>>>;
  GetCustomerDailyLiving?: Maybe<CustomerDailyLiving>;
  GetCustomerDailyLivings?: Maybe<Array<Maybe<CustomerDailyLiving>>>;
  GetCustomerIncidents?: Maybe<Array<Maybe<VisitIncident>>>;
  GetCustomerInterests?: Maybe<Array<Maybe<CustomerInterest>>>;
  GetCustomerInvoice?: Maybe<Invoice>;
  GetCustomerInvoices?: Maybe<Array<Maybe<Invoice>>>;
  GetCustomerMedications?: Maybe<Array<Maybe<CustomerMedication>>>;
  GetCustomerNeed?: Maybe<CustomerNeedType>;
  GetCustomerPackage?: Maybe<CustomerPackage>;
  GetCustomerPackages?: Maybe<Array<Maybe<CustomerPackage>>>;
  GetCustomerVisitPlanResults?: Maybe<Array<Maybe<VisitPlanResult>>>;
  GetCustomerVisits?: Maybe<Array<Maybe<Visit>>>;
  GetCustomers?: Maybe<Array<Maybe<Customer>>>;
  GetCustomersForFamilyMember?: Maybe<Array<Maybe<Customer>>>;
  GetDailyLivingRoutines?: Maybe<Array<Maybe<DailyLivingRoutine>>>;
  GetDayShiftsForTeam?: Maybe<Array<Maybe<ShiftRun>>>;
  GetExpenses?: Maybe<Array<Maybe<Expense>>>;
  GetFamilyMember?: Maybe<Family>;
  GetFamilyMembersByTeam?: Maybe<Array<Maybe<Family>>>;
  GetForms?: Maybe<Array<Maybe<Form>>>;
  GetImage?: Maybe<Image>;
  GetLeave?: Maybe<Array<Maybe<Absence>>>;
  GetLeaveForTeam?: Maybe<Array<Maybe<Absence>>>;
  GetLocalAuthorities?: Maybe<Array<Maybe<LocalAuthority>>>;
  GetMedications?: Maybe<Array<Maybe<Medication>>>;
  GetMileageVisitsForCustomerByDateRange?: Maybe<Array<Maybe<Shift>>>;
  GetNewChatMessages?: Maybe<Array<Maybe<NewChatMessages>>>;
  GetNotifications?: Maybe<Array<Maybe<Notification>>>;
  GetPayrollReport?: Maybe<Array<Maybe<Shift>>>;
  GetPlaceholderVisitsByRota?: Maybe<Array<Maybe<PlaceholderVisit>>>;
  GetReportingHours?: Maybe<Array<Maybe<ReportingHours>>>;
  GetReportingHoursPeriod?: Maybe<Array<Maybe<ReportingHoursPeriod>>>;
  GetReports?: Maybe<Array<Maybe<Report>>>;
  GetRisk?: Maybe<Risk>;
  GetRisks?: Maybe<Array<Maybe<Risk>>>;
  GetRotas?: Maybe<RotaGroup>;
  GetShifts?: Maybe<Array<Maybe<Shift>>>;
  GetSupportWorker?: Maybe<SupportWorker>;
  GetSupportWorkerRoles?: Maybe<Array<Maybe<SupportWorkerRole>>>;
  GetSupportWorkerVisitsInProgress?: Maybe<Array<Maybe<Shift>>>;
  GetSupportWorkers?: Maybe<Array<Maybe<SupportWorker>>>;
  GetSupportWorkersVisitReport?: Maybe<Array<Maybe<SupportWorkerVisitReport>>>;
  GetSupportedPeopleFromHubspot?: Maybe<Array<Maybe<HubSpotCustomer>>>;
  GetSupportedPersonFromHubspot?: Maybe<HubSpotCustomer>;
  GetSupportedPersonsVisitReport?: Maybe<Array<Maybe<SupportedPersonVisitReport>>>;
  GetSurveyAnswers?: Maybe<SurveyAnswer>;
  GetSurveyQuestions?: Maybe<Question>;
  GetSurveyReport?: Maybe<CandidateReport>;
  GetSurveyReports?: Maybe<Array<Maybe<CandidateReportList>>>;
  GetTeam?: Maybe<Team>;
  GetTeamAnswerStatus?: Maybe<Array<Maybe<TeamAnswer>>>;
  GetTeamMeetings?: Maybe<Array<Maybe<TeamMeeting>>>;
  GetTeams?: Maybe<Array<Maybe<Team>>>;
  GetTemplates?: Maybe<Array<Maybe<Template>>>;
  GetThriveBookings?: Maybe<Array<Maybe<ThriveBooking>>>;
  GetVisit?: Maybe<Visit>;
  GetVisitCancellationReport?: Maybe<Array<Maybe<VisitCancellation>>>;
  GetVisitIncident?: Maybe<VisitIncident>;
  GetVisitIncidents?: Maybe<Array<Maybe<VisitIncident>>>;
  GetVisitWithObservations?: Maybe<VisitWithObservationsType>;
  GetVisitsForCustomerByDateRange?: Maybe<Array<Maybe<Shift>>>;
  GetWorkshop?: Maybe<Workshop>;
  GetWorkshops?: Maybe<Array<Maybe<Workshop>>>;
};


export type QueryGetAgreedRotasArgs = {
  query?: InputMaybe<AgreedRotaSearch>;
};


export type QueryGetAgreedShiftsArgs = {
  query?: InputMaybe<AgreedShiftRunSearch>;
};


export type QueryGetAgreedVisitsOptimisedArgs = {
  query?: InputMaybe<OptimisedSearch>;
};


export type QueryGetAllReportingHoursPeriodArgs = {
  query?: InputMaybe<ReportingHoursByDateQuery>;
};


export type QueryGetAnswerStatusArgs = {
  query?: InputMaybe<GetAnswerSearch>;
};


export type QueryGetAnswersArgs = {
  query?: InputMaybe<AnswerSearch>;
};


export type QueryGetAscotExpectedOutcomeArgs = {
  query?: InputMaybe<AscotExpectedOutcomeByIdSearch>;
};


export type QueryGetAscotExpectedOutcomesArgs = {
  query?: InputMaybe<AscotExpectedOutcomeListSearch>;
};


export type QueryGetAscotReviewArgs = {
  query?: InputMaybe<AscotReviewByIdSearch>;
};


export type QueryGetAscotReviewsArgs = {
  query?: InputMaybe<AscotReviewListSearch>;
};


export type QueryGetAssessmentArgs = {
  query?: InputMaybe<AssessmentByIdSearch>;
};


export type QueryGetAssessmentsArgs = {
  query?: InputMaybe<AssesssmentListSearch>;
};


export type QueryGetBirdieVisitsArgs = {
  query?: InputMaybe<VisitSearch>;
};


export type QueryGetBuddieGroupsArgs = {
  query?: InputMaybe<BuddieGroupSearch>;
};


export type QueryGetBuddiesForSupportWorkerArgs = {
  query?: InputMaybe<BuddieForSupportWorkerSearch>;
};


export type QueryGetCancelledVisitsReportArgs = {
  query?: InputMaybe<PayrollReportSearch>;
};


export type QueryGetCandidateArgs = {
  query?: InputMaybe<GetCandidateSearch>;
};


export type QueryGetCandidatesArgs = {
  query?: InputMaybe<CandidateSearch>;
};


export type QueryGetCandidatesForWorkshopArgs = {
  query?: InputMaybe<CandidateForWorkshopSearchInput>;
};


export type QueryGetChatMessagesByRoomArgs = {
  query?: InputMaybe<ChatMessageSearch>;
};


export type QueryGetChatRoomsArgs = {
  query?: InputMaybe<ChatRoomSearch>;
};


export type QueryGetConfirmationPracticeGroupsArgs = {
  query?: InputMaybe<ConfirmationPracticeGroupSearch>;
};


export type QueryGetConfirmationPracticeQuestionsArgs = {
  query?: InputMaybe<ConfirmationPracticeQuestionListSearch>;
};


export type QueryGetConfirmationPracticesReportArgs = {
  query?: InputMaybe<ConfirmationPracticesReportSearch>;
};


export type QueryGetCourseReportsBySupportWorkerIdArgs = {
  query?: InputMaybe<CourseReportSearch>;
};


export type QueryGetCrossTeamVisitsArgs = {
  query?: InputMaybe<CrossTeamSearch>;
};


export type QueryGetCustomerArgs = {
  query?: InputMaybe<CustomerByIdSearch>;
};


export type QueryGetCustomerActivitiesArgs = {
  query?: InputMaybe<CustomerActivitiesSearch>;
};


export type QueryGetCustomerContactsArgs = {
  query?: InputMaybe<CustomerByIdSearch>;
};


export type QueryGetCustomerDailyLivingArgs = {
  query?: InputMaybe<CustomerDailyLivingSearch>;
};


export type QueryGetCustomerDailyLivingsArgs = {
  query?: InputMaybe<CustomerDailyLivingsSearch>;
};


export type QueryGetCustomerIncidentsArgs = {
  query?: InputMaybe<VisitIncidentSearch>;
};


export type QueryGetCustomerInvoiceArgs = {
  query?: InputMaybe<InvoiceSearch>;
};


export type QueryGetCustomerInvoicesArgs = {
  query?: InputMaybe<InvoiceSearch>;
};


export type QueryGetCustomerMedicationsArgs = {
  query?: InputMaybe<CustomerMedicationSearch>;
};


export type QueryGetCustomerNeedArgs = {
  query?: InputMaybe<CustomerNeedSearch>;
};


export type QueryGetCustomerPackageArgs = {
  query?: InputMaybe<CustomerPackageSearch>;
};


export type QueryGetCustomerPackagesArgs = {
  query?: InputMaybe<CustomerPackageSearch>;
};


export type QueryGetCustomerVisitPlanResultsArgs = {
  query?: InputMaybe<VisitPlanResultSearch>;
};


export type QueryGetCustomerVisitsArgs = {
  query?: InputMaybe<CustomerVisitsInput>;
};


export type QueryGetCustomersArgs = {
  query?: InputMaybe<CustomerSearch>;
};


export type QueryGetCustomersForFamilyMemberArgs = {
  query?: InputMaybe<FamilyMemberCustomersSearch>;
};


export type QueryGetDayShiftsForTeamArgs = {
  query?: InputMaybe<GetDayShiftsForTeamSearch>;
};


export type QueryGetExpensesArgs = {
  query?: InputMaybe<ExpenseSearchInput>;
};


export type QueryGetFamilyMemberArgs = {
  query?: InputMaybe<FamilySearch>;
};


export type QueryGetFamilyMembersByTeamArgs = {
  query?: InputMaybe<FamilyMembersSearch>;
};


export type QueryGetFormsArgs = {
  query?: InputMaybe<FormListSearch>;
};


export type QueryGetImageArgs = {
  query?: InputMaybe<ImageSearch>;
};


export type QueryGetLeaveArgs = {
  query?: InputMaybe<AbsenceSearch>;
};


export type QueryGetLeaveForTeamArgs = {
  query?: InputMaybe<AbsenceSearchTeam>;
};


export type QueryGetMedicationsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMileageVisitsForCustomerByDateRangeArgs = {
  query?: InputMaybe<GetVisitsForCustomerByDateRangeSearch>;
};


export type QueryGetNewChatMessagesArgs = {
  query?: InputMaybe<NewChatMessagesSearch>;
};


export type QueryGetNotificationsArgs = {
  query?: InputMaybe<NotificationSearch>;
};


export type QueryGetPayrollReportArgs = {
  query?: InputMaybe<PayrollReportSearch>;
};


export type QueryGetPlaceholderVisitsByRotaArgs = {
  query?: InputMaybe<PlaceholderVisitSearch>;
};


export type QueryGetReportingHoursArgs = {
  query?: InputMaybe<ReportingHoursSearch>;
};


export type QueryGetReportingHoursPeriodArgs = {
  query?: InputMaybe<ReportingHoursPeriodQuery>;
};


export type QueryGetReportsArgs = {
  query?: InputMaybe<ReportSearch>;
};


export type QueryGetRiskArgs = {
  query?: InputMaybe<RiskByIdSearch>;
};


export type QueryGetRisksArgs = {
  query?: InputMaybe<RiskListSearch>;
};


export type QueryGetRotasArgs = {
  query?: InputMaybe<RotaSearch>;
};


export type QueryGetShiftsArgs = {
  query?: InputMaybe<ShiftSearch>;
};


export type QueryGetSupportWorkerArgs = {
  query?: InputMaybe<SupportWorkerSearch>;
};


export type QueryGetSupportWorkerVisitsInProgressArgs = {
  query?: InputMaybe<SupportWorkerVisitsInProgressInput>;
};


export type QueryGetSupportWorkersArgs = {
  query?: InputMaybe<SupportWorkerSearch>;
};


export type QueryGetSupportWorkersVisitReportArgs = {
  query?: InputMaybe<SupportWorkerVisitReportSearch>;
};


export type QueryGetSupportedPeopleFromHubspotArgs = {
  query?: InputMaybe<HubSpotSearch>;
};


export type QueryGetSupportedPersonFromHubspotArgs = {
  query?: InputMaybe<HubSpotSearch>;
};


export type QueryGetSupportedPersonsVisitReportArgs = {
  query?: InputMaybe<SupportedPersonVisitReportSearch>;
};


export type QueryGetSurveyAnswersArgs = {
  query?: InputMaybe<SurveyAnswerSearch>;
};


export type QueryGetSurveyReportArgs = {
  query?: InputMaybe<SurveyReportSearch>;
};


export type QueryGetTeamArgs = {
  query?: InputMaybe<TeamSearch>;
};


export type QueryGetTeamAnswerStatusArgs = {
  query?: InputMaybe<TeamAnswerStatusSearch>;
};


export type QueryGetTeamMeetingsArgs = {
  query?: InputMaybe<TeamMeetingsSearch>;
};


export type QueryGetTeamsArgs = {
  query?: InputMaybe<TeamSearch>;
};


export type QueryGetThriveBookingsArgs = {
  query?: InputMaybe<ThriveBookingSearch>;
};


export type QueryGetVisitArgs = {
  query?: InputMaybe<VisitByIdSearch>;
};


export type QueryGetVisitCancellationReportArgs = {
  query?: InputMaybe<VisitCancellationSearch>;
};


export type QueryGetVisitIncidentArgs = {
  query?: InputMaybe<VisitIncidentByIdSearch>;
};


export type QueryGetVisitIncidentsArgs = {
  query?: InputMaybe<VisitIncidentSearch>;
};


export type QueryGetVisitWithObservationsArgs = {
  query?: InputMaybe<VisitWithObservationsSearch>;
};


export type QueryGetVisitsForCustomerByDateRangeArgs = {
  query?: InputMaybe<GetVisitsForCustomerByDateRangeSearch>;
};


export type QueryGetWorkshopArgs = {
  query?: InputMaybe<WorkshopSearch>;
};

export type Question = {
  __typename?: 'Question';
  customer?: Maybe<Array<Maybe<QuestionItem>>>;
  interests?: Maybe<Array<Maybe<QuestionItem>>>;
  personality?: Maybe<Array<Maybe<QuestionItem>>>;
  values?: Maybe<Array<Maybe<QuestionItem>>>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  options?: Maybe<Array<Maybe<QuestionAnswerOption>>>;
  type?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
};

export type QuestionAnswerInput = {
  options?: InputMaybe<Array<InputMaybe<QuestionAnswerOptionInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  variant?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAnswerOption = {
  __typename?: 'QuestionAnswerOption';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type QuestionAnswerOptionInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionItem = {
  __typename?: 'QuestionItem';
  _id?: Maybe<Scalars['String']['output']>;
  factor?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  pic?: Maybe<Scalars['String']['output']>;
  polarity?: Maybe<Scalars['Float']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ReadChatMessagesInput = {
  roomId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum ReasonForNotHired {
  DriveCar = 'DriveCar',
  Hours = 'Hours',
  Location = 'Location',
  NotInterested = 'NotInterested',
  NotSuitable = 'NotSuitable',
  Other = 'Other',
  PersonalCare = 'PersonalCare',
  Salary = 'Salary',
  Sponsorship = 'Sponsorship'
}

export type RecipientEmailInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
};

export type RecipientListInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type RecipientSmsInput = {
  fromName?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshSwCourseReportInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveAgreedRotaInput = {
  id: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveAgreedShiftInput = {
  agreedRotaId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveAgreedShiftRunInput = {
  agreedRotaId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveDocumentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveNotificationsInput = {
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RemovePlaceholderVisitInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveTemplateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Report = {
  __typename?: 'Report';
  endDateTime?: Maybe<Scalars['Float']['output']>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ReportSearch = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
};

export type ReportingHours = {
  __typename?: 'ReportingHours';
  bankedHours?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<MileageReportingHours>;
  owner?: Maybe<SupportWorkerReportingHours>;
  reportingHoursPeriodId?: Maybe<Scalars['String']['output']>;
  rota?: Maybe<Rota>;
  secondarySupportWorkerId?: Maybe<Scalars['String']['output']>;
  shift?: Maybe<ShiftRun>;
  shiftId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visit?: Maybe<Shift>;
};

export type ReportingHoursByDateQuery = {
  period?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ReportingHoursPeriod = {
  __typename?: 'ReportingHoursPeriod';
  annualLeave?: Maybe<Scalars['Float']['output']>;
  bankedHours?: Maybe<Scalars['Float']['output']>;
  bankedHoursAssignedOnVisits?: Maybe<Scalars['Float']['output']>;
  birdieId?: Maybe<Scalars['String']['output']>;
  contractedHours?: Maybe<Scalars['Float']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  endDateTime?: Maybe<Scalars['Float']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceEndDateTime?: Maybe<Scalars['Float']['output']>;
  invoiceStartDateTime?: Maybe<Scalars['Float']['output']>;
  meetings?: Maybe<Scalars['Float']['output']>;
  mileage?: Maybe<Scalars['Float']['output']>;
  mileageOther?: Maybe<Scalars['Float']['output']>;
  mileageWithCustomer?: Maybe<Scalars['Float']['output']>;
  overtime?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  remainingBankedHours?: Maybe<Scalars['Float']['output']>;
  sickLeave?: Maybe<Scalars['Float']['output']>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  timeOnActivities?: Maybe<Scalars['Float']['output']>;
  timeOnVisits?: Maybe<Scalars['Float']['output']>;
};

export type ReportingHoursPeriodQuery = {
  id?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ReportingHoursSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type Response = {
  __typename?: 'Response';
  date?: Maybe<Scalars['Float']['output']>;
  message: Scalars['String']['output'];
};

export type RevertVisit = {
  rotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type Risk = {
  __typename?: 'Risk';
  createdAt?: Maybe<Scalars['Float']['output']>;
  createdBySupportWorkerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mitigation?: Maybe<Scalars['String']['output']>;
  relatedAssessment?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  risk?: Maybe<Scalars['String']['output']>;
  riskLevel?: Maybe<Scalars['String']['output']>;
  supportedPersonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Float']['output']>;
};

export type RiskByIdSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RiskInput = {
  createdAt?: InputMaybe<Scalars['Float']['input']>;
  createdBySupportWorkerId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mitigation?: InputMaybe<Scalars['String']['input']>;
  relatedAssessment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  risk?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<Scalars['String']['input']>;
  supportedPersonId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Float']['input']>;
};

export type RiskListSearch = {
  supportedPersonId?: InputMaybe<Scalars['String']['input']>;
};

export type Rota = {
  __typename?: 'Rota';
  endRotaDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startRotaDateTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type RotaDelete = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type RotaGroup = {
  __typename?: 'RotaGroup';
  current?: Maybe<Rota>;
  future?: Maybe<Array<Maybe<Rota>>>;
  previous?: Maybe<Array<Maybe<Rota>>>;
};

export type RotaInput = {
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['String']['input'];
};

export type RotaSearch = {
  endRotaDateTime?: InputMaybe<Scalars['Float']['input']>;
  startRotaDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['String']['input'];
};

export type SafeGuarding = {
  __typename?: 'SafeGuarding';
  allergies?: Maybe<Array<Maybe<Allergies>>>;
  dnr?: Maybe<Scalars['Boolean']['output']>;
};

export type SafeGuardingInput = {
  allergies?: InputMaybe<Array<InputMaybe<AllergiesInput>>>;
  dnr?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaveArrayResponse = {
  __typename?: 'SaveArrayResponse';
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message: Scalars['String']['output'];
};

export type SaveCandidateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  canDrive?: InputMaybe<Scalars['Boolean']['input']>;
  circleId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentStatus?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['Float']['input']>;
  eligibleToWork?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  history?: InputMaybe<Array<InputMaybe<CandidateHistoryInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  jobRole?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  reasonNotHired?: InputMaybe<Scalars['String']['input']>;
  reasonNotHiredOther?: InputMaybe<Scalars['String']['input']>;
  roleAppeal?: InputMaybe<Scalars['String']['input']>;
  scorecard?: InputMaybe<Array<InputMaybe<ScorecardInput>>>;
  shadowFeedback?: InputMaybe<CandidateShadowFeedbackInput>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  tracking?: InputMaybe<CandidateTrackingInput>;
  whyYou?: InputMaybe<Scalars['String']['input']>;
  workshop?: InputMaybe<CandidateWorkshopInput>;
  workshopFeedback?: InputMaybe<CandidateWorkshopFeedbackInput>;
};

export type SaveCustomerContactInput = {
  contacts?: InputMaybe<Array<InputMaybe<CustomerContactInput>>>;
  customerId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type SaveCustomerDailyLivingInput = {
  category: Scalars['String']['input'];
  customerDailyLivingGoal?: InputMaybe<Array<InputMaybe<CustomerDailyLivingGoalInput>>>;
  customerId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  personalOutcome: Scalars['String']['input'];
};

export type SaveCustomerHealthIssueInput = {
  customerId: Scalars['String']['input'];
  healthIssues?: InputMaybe<Array<InputMaybe<CustomerHealthIssueInput>>>;
  teamId: Scalars['String']['input'];
};

export type SaveCustomerHomeAccessInput = {
  accessImage?: InputMaybe<Scalars['String']['input']>;
  accessNotes?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  livesWith?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type SaveCustomerInterestsInput = {
  customerId: Scalars['String']['input'];
  interests?: InputMaybe<Array<InputMaybe<CustomerInterestSaveInput>>>;
  teamId: Scalars['String']['input'];
};

export type SaveCustomerLifeEventInput = {
  customerId: Scalars['String']['input'];
  lifeEvents?: InputMaybe<Array<InputMaybe<CustomerLifeEventInput>>>;
  teamId: Scalars['String']['input'];
};

export type SaveCustomerSafeGuardingInput = {
  customerId: Scalars['String']['input'];
  safeguarding?: InputMaybe<SafeGuardingInput>;
  teamId: Scalars['String']['input'];
};

export type SaveDocumentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type SaveInvoiceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cancelledVisits?: InputMaybe<InvoiceCsvItemInput>;
  care?: InputMaybe<InvoiceCsvItemInput>;
  careItems?: InputMaybe<Array<InputMaybe<InvoiceItemInput>>>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Scalars['Float']['input']>;
  dueDate?: InputMaybe<Scalars['Float']['input']>;
  expenseItems?: InputMaybe<Array<InputMaybe<InvoiceExpenseInput>>>;
  expenses?: InputMaybe<InvoiceCsvItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  indexKey?: InputMaybe<Scalars['Float']['input']>;
  invoiceDate?: InputMaybe<Scalars['Float']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceType?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<InvoiceCsvItemInput>;
  mileageItems?: InputMaybe<Array<InputMaybe<InvoiceItemInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneOffVisits?: InputMaybe<InvoiceCsvItemInput>;
  payrollEndDate?: InputMaybe<Scalars['Float']['input']>;
  payrollStartDate?: InputMaybe<Scalars['Float']['input']>;
  proRataDescription?: InputMaybe<Scalars['String']['input']>;
  proRataRate?: InputMaybe<Scalars['String']['input']>;
  proRataType?: InputMaybe<ProRataInput>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  thrive?: InputMaybe<InvoiceCsvItemInput>;
  thriveBookingItems?: InputMaybe<Array<InputMaybe<InvoiceItemThriveBookingInput>>>;
  thriveVat?: InputMaybe<InvoiceCsvItemInput>;
  vat?: InputMaybe<InvoiceCsvItemInput>;
};

export type SavePlaceholderVisitInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftRunId?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  supportWorker?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type SaveReportingHoursInput = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type SaveResponse = {
  __typename?: 'SaveResponse';
  date?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type SaveTeamMeetingInput = {
  apologies: Array<InputMaybe<ApologyInput>>;
  attendees: Array<InputMaybe<Scalars['String']['input']>>;
  date: Scalars['Float']['input'];
  teamId: Scalars['String']['input'];
};

export type SaveTemplateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MessageType>;
};

export type SaveVisitImagesInput = {
  id: Scalars['String']['input'];
  rotaId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  visitImages: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SaveVisitNotesInput = {
  id: Scalars['String']['input'];
  rotaId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  visitNotes: Scalars['String']['input'];
};

export type SaveWorkshopInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  candidates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  date?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type Scorecard = {
  __typename?: 'Scorecard';
  answer?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionType?: Maybe<Scalars['String']['output']>;
};

export type ScorecardInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  questionType?: InputMaybe<Scalars['String']['input']>;
};

export type Select = {
  id: Scalars['String']['input'];
};

export type SendCandidateMessagesInput = {
  email?: InputMaybe<RecipientEmailInput>;
  selectedRecipients?: InputMaybe<Array<InputMaybe<RecipientListInput>>>;
  sms?: InputMaybe<RecipientSmsInput>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SendSmsInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SeverityResponse = {
  __typename?: 'SeverityResponse';
  date?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
};

export type Shift = {
  __typename?: 'Shift';
  activityType?: Maybe<Scalars['String']['output']>;
  birdieId?: Maybe<Scalars['String']['output']>;
  cancelledDateTime?: Maybe<Scalars['Float']['output']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<VisitMove>;
  endDateTime: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  isToBeRescheduled?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  oneOffVisitNote?: Maybe<Scalars['String']['output']>;
  oneOffVisitReason?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<VisitMove>;
  progress?: Maybe<Scalars['String']['output']>;
  rotaId?: Maybe<Scalars['String']['output']>;
  secondarySupportWorker?: Maybe<SupportWorker>;
  secondarySupportWorkerId?: Maybe<Scalars['String']['output']>;
  shiftId: Scalars['String']['output'];
  shiftRun?: Maybe<ShiftRun>;
  startDateTime: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  supportWorker?: Maybe<SupportWorker>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  travelTime?: Maybe<Scalars['Float']['output']>;
  travelWithCustomerDestination?: Maybe<Scalars['String']['output']>;
  travelWithCustomerMileage?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visitId: Scalars['String']['output'];
  week?: Maybe<Scalars['String']['output']>;
  whoCancelled?: Maybe<Scalars['String']['output']>;
};

export type ShiftInput = {
  item: ShiftInputItem;
  rotaId: Scalars['String']['input'];
};

export type ShiftInputItem = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  cancelledDateTime?: InputMaybe<Scalars['Float']['input']>;
  customerId: Scalars['String']['input'];
  endDateTime: Scalars['Float']['input'];
  isToBeRescheduled?: InputMaybe<Scalars['String']['input']>;
  oneOffVisitNote?: InputMaybe<Scalars['String']['input']>;
  oneOffVisitReason?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  secondarySupportWorkerId?: InputMaybe<Scalars['String']['input']>;
  shiftEndDateTime?: InputMaybe<Scalars['Float']['input']>;
  shiftStartDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime: Scalars['Float']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
  travelTime?: InputMaybe<Scalars['Float']['input']>;
  travelWithCustomerDestination?: InputMaybe<Scalars['String']['input']>;
  travelWithCustomerMileage?: InputMaybe<Scalars['Float']['input']>;
  type: Scalars['String']['input'];
  visitId?: InputMaybe<Scalars['String']['input']>;
  week?: InputMaybe<Scalars['String']['input']>;
};

export type ShiftRemove = {
  id: Scalars['String']['input'];
  rotaId: Scalars['String']['input'];
};

export type ShiftRun = {
  __typename?: 'ShiftRun';
  endDateTime: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['String']['output']>;
  rotaId: Scalars['String']['output'];
  startDateTime: Scalars['Float']['output'];
  supportWorker?: Maybe<SupportWorker>;
  teamId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type ShiftRunChangeTimeInput = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ShiftRunChangeTypeInput = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ShiftRunSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ShiftRunUpdate = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  operation?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ShiftSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  secondarySupportWorkerId?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  travelTime?: InputMaybe<Scalars['Float']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type ShiftUpdate = {
  item: ShiftUpdateItem;
  operation?: InputMaybe<Scalars['String']['input']>;
  rotaId: Scalars['String']['input'];
  shiftId: Scalars['String']['input'];
};

export type ShiftUpdateItem = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  cancelledDateTime?: InputMaybe<Scalars['Float']['input']>;
  customerId: Scalars['String']['input'];
  endDateTime: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  isToBeRescheduled?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  oneOffVisitNote?: InputMaybe<Scalars['String']['input']>;
  oneOffVisitReason?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  secondarySupportWorkerId?: InputMaybe<Scalars['String']['input']>;
  startDateTime: Scalars['Float']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
  travelTime?: InputMaybe<Scalars['Float']['input']>;
  travelWithCustomerDestination?: InputMaybe<Scalars['String']['input']>;
  travelWithCustomerMileage?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
  week?: InputMaybe<Scalars['String']['input']>;
  whoCancelled?: InputMaybe<Scalars['String']['input']>;
};

export type SocialParticipationInputType = {
  satisfactionWithSocialLife?: InputMaybe<Scalars['String']['input']>;
  socialParticipation?: InputMaybe<Scalars['String']['input']>;
  unmetNeeds?: InputMaybe<UnmeetNeedInputType>;
};

export type SocialParticipationType = {
  __typename?: 'SocialParticipationType';
  satisfactionWithSocialLife?: Maybe<Scalars['String']['output']>;
  socialParticipation?: Maybe<Scalars['String']['output']>;
  unmetNeeds?: Maybe<UnmeetNeedType>;
};

export type SubmitReportingHourInput = {
  period?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  OnCreateChatRoom?: Maybe<SaveResponse>;
  OnCreateMessageByRoomId?: Maybe<ChatMessage>;
};


export type SubscriptionOnCreateMessageByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};

export type SupportWorker = {
  __typename?: 'SupportWorker';
  address?: Maybe<Scalars['String']['output']>;
  avatar: Scalars['String']['output'];
  birdieId?: Maybe<Scalars['String']['output']>;
  circleId: Scalars['String']['output'];
  circleName: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  cognito?: Maybe<Scalars['String']['output']>;
  contractedHours: Scalars['Float']['output'];
  dateCreated?: Maybe<Scalars['Float']['output']>;
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  employmentStatus: Scalars['String']['output'];
  externalId: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isOnLeave: Scalars['Boolean']['output'];
  isTerminated: Scalars['Boolean']['output'];
  lastLoggedInDate?: Maybe<Scalars['Float']['output']>;
  lastName: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  permissions?: Maybe<Permissions>;
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  profileData?: Maybe<SupportWorkerProfile>;
  role?: Maybe<SupportWorkerRole>;
  roleId?: Maybe<Scalars['String']['output']>;
  rota?: Maybe<Rota>;
  startedAt?: Maybe<Scalars['Float']['output']>;
  teamId: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
  teamPeriod?: Maybe<Array<Maybe<TeamPeriod>>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type SupportWorkerChatTokenInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type SupportWorkerProfile = {
  __typename?: 'SupportWorkerProfile';
  aboutMe?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  matters?: Maybe<Scalars['String']['output']>;
  support?: Maybe<Scalars['String']['output']>;
};

export type SupportWorkerProfileInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lastLoggedInDate?: InputMaybe<Scalars['Float']['input']>;
  permissions?: InputMaybe<InputPermissions>;
  profileData?: InputMaybe<InputSupportWorkerProfile>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type SupportWorkerReportSearch = {
  endDateTime: Scalars['Float']['input'];
  startDateTime: Scalars['Float']['input'];
  teamId: Scalars['String']['input'];
};

export type SupportWorkerReportingHours = {
  __typename?: 'SupportWorkerReportingHours';
  birdieId?: Maybe<Scalars['String']['output']>;
  contractedHours?: Maybe<Scalars['Float']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
};

export type SupportWorkerRole = {
  __typename?: 'SupportWorkerRole';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SupportWorkerSearch = {
  circleId?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isOnLeave?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type SupportWorkerSurveyReportInput = {
  reportId?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
};

export type SupportWorkerVisitReport = {
  __typename?: 'SupportWorkerVisitReport';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  observationDate?: Maybe<Scalars['String']['output']>;
  observationDay?: Maybe<Scalars['String']['output']>;
  observationWeek?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  shiftDurationInMinutes?: Maybe<Scalars['Float']['output']>;
  shiftEnd?: Maybe<Scalars['String']['output']>;
  shiftStart?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
};

export type SupportWorkerVisitReportSearch = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type SupportWorkerVisitsInProgressInput = {
  date: Scalars['Float']['input'];
  supportWorkerId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type SupportedPersonVisitReport = {
  __typename?: 'SupportedPersonVisitReport';
  joinDate?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  observationDate?: Maybe<Scalars['String']['output']>;
  observationDay?: Maybe<Scalars['String']['output']>;
  observationEndTime?: Maybe<Scalars['String']['output']>;
  observationStartTime?: Maybe<Scalars['String']['output']>;
  observationWeek?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  supportedPersonId?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  visitDurationInMinutes?: Maybe<Scalars['Float']['output']>;
};

export type SupportedPersonVisitReportSearch = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  answers?: Maybe<AnswersItem>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  userId?: Maybe<Scalars['String']['output']>;
  withinDistance?: Maybe<Scalars['Float']['output']>;
};

export type SurveyAnswerSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyReportSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SyncReportingHourInput = {
  period?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type SystemInfoInput = {
  agent?: InputMaybe<AgentInput>;
  browserWindow?: InputMaybe<BrowserWindowInput>;
};

export type Team = {
  __typename?: 'Team';
  activeTeam?: Maybe<Scalars['Boolean']['output']>;
  birdieId?: Maybe<Scalars['String']['output']>;
  circleId: Scalars['String']['output'];
  circleName: Scalars['String']['output'];
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  prefix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  teamId: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
};

export type TeamAnswer = {
  __typename?: 'TeamAnswer';
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type TeamAnswerStatusSearch = {
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamInput = {
  activeTeam?: InputMaybe<Scalars['Boolean']['input']>;
  circleId: Scalars['String']['input'];
  circleName: Scalars['String']['input'];
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};

export type TeamMeeting = {
  __typename?: 'TeamMeeting';
  apologies: Array<Maybe<Apology>>;
  attendees: Array<Maybe<Scalars['String']['output']>>;
  date: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  note?: Maybe<Note>;
  teamId: Scalars['String']['output'];
};

export type TeamMeetingsSearch = {
  teamId: Scalars['String']['input'];
};

export type TeamPeriod = {
  __typename?: 'TeamPeriod';
  date?: Maybe<Scalars['Float']['output']>;
  teamJoinedId?: Maybe<Scalars['String']['output']>;
  teamLeftId?: Maybe<Scalars['String']['output']>;
};

export type TeamSearch = {
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
};

export type Template = {
  __typename?: 'Template';
  dateUpdated?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MessageType>;
};

export type ThriveAddress = {
  __typename?: 'ThriveAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  long?: Maybe<Scalars['Float']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type ThriveBooking = {
  __typename?: 'ThriveBooking';
  address?: Maybe<ThriveClientAddress>;
  amITheSupportedPerson?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<ThriveCategory>;
  customer?: Maybe<ThriveCustomer>;
  endDateTime?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  isFirstBooking?: Maybe<Scalars['Boolean']['output']>;
  isRecurring?: Maybe<Scalars['Boolean']['output']>;
  partner?: Maybe<ThrivePartner>;
  partnerName?: Maybe<Scalars['String']['output']>;
  partnerPrice?: Maybe<ThrivePartnerPrice>;
  preQualifyingQuestions?: Maybe<Array<Maybe<ThrivePreQualifyingQuestions>>>;
  product?: Maybe<ThriveProduct>;
  reminderSent?: Maybe<Scalars['Boolean']['output']>;
  startDateTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supportedPerson?: Maybe<ThriveSupportedPerson>;
  supportedPersonId?: Maybe<Scalars['String']['output']>;
};

export type ThriveBookingSearch = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
};

export type ThriveCategory = {
  __typename?: 'ThriveCategory';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Array<Maybe<ThriveProduct>>>;
  storyblokSlug?: Maybe<Scalars['String']['output']>;
};

export type ThriveClientAddress = {
  __typename?: 'ThriveClientAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressNotes?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type ThriveCustomer = {
  __typename?: 'ThriveCustomer';
  address?: Maybe<Array<Maybe<ThriveClientAddress>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  otherInformations?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  supportedPeople?: Maybe<Array<Maybe<ThriveSupportedPerson>>>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type ThriveEmail = {
  __typename?: 'ThriveEmail';
  subject?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type ThriveImage = {
  __typename?: 'ThriveImage';
  id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ThrivePartner = {
  __typename?: 'ThrivePartner';
  address?: Maybe<ThriveAddress>;
  businessName?: Maybe<Scalars['String']['output']>;
  dbsChecked?: Maybe<Scalars['Boolean']['output']>;
  dbsExpiryDate?: Maybe<Scalars['Float']['output']>;
  deactivationReason?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<ThriveImage>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<Maybe<ThrivePartnerPrice>>>;
  qualifications?: Maybe<Scalars['String']['output']>;
  qualificationsChecked?: Maybe<Scalars['Boolean']['output']>;
  referencesReceived?: Maybe<Scalars['Boolean']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  vetted?: Maybe<Scalars['Boolean']['output']>;
  workingArea?: Maybe<Array<Maybe<ThrivePartnerWorkingArea>>>;
  yearsQualified?: Maybe<Scalars['Float']['output']>;
};

export type ThrivePartnerPrice = {
  __typename?: 'ThrivePartnerPrice';
  basePrice?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  firstBookingPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  memberPrice?: Maybe<Scalars['Float']['output']>;
  partnerBusinessName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['String']['output']>;
  partnerName?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
};

export type ThrivePartnerWorkingArea = {
  __typename?: 'ThrivePartnerWorkingArea';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type ThrivePreQualifyingQuestions = {
  __typename?: 'ThrivePreQualifyingQuestions';
  answers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  question?: Maybe<Scalars['String']['output']>;
};

export type ThriveProduct = {
  __typename?: 'ThriveProduct';
  categoryId?: Maybe<Scalars['String']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ThriveSupportedPerson = {
  __typename?: 'ThriveSupportedPerson';
  address?: Maybe<ThriveClientAddress>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type TravelTime = {
  id: Scalars['String']['input'];
  mileage: Scalars['Float']['input'];
  travelWithCustomerDestination?: InputMaybe<Scalars['String']['input']>;
  travelWithCustomerMileage: Scalars['Float']['input'];
};

export type UnmeetNeedInputType = {
  answer?: InputMaybe<Scalars['String']['input']>;
  answer2?: InputMaybe<Scalars['String']['input']>;
  answer3?: InputMaybe<Scalars['String']['input']>;
};

export type UnmeetNeedType = {
  __typename?: 'UnmeetNeedType';
  answer?: Maybe<Scalars['String']['output']>;
  answer2?: Maybe<Scalars['String']['output']>;
  answer3?: Maybe<Scalars['String']['output']>;
};

export type UpdateActivityInput = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAgreedVisitInput = {
  items?: InputMaybe<Array<InputMaybe<UpdateAgreedVisitItemInput>>>;
};

export type UpdateAgreedVisitItemInput = {
  agreedRotaId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftId?: InputMaybe<Scalars['String']['input']>;
  agreedShiftRunId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFamilyMemberInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportingHourInput = {
  bankedHours?: InputMaybe<Scalars['Float']['input']>;
  bankedMinutes?: InputMaybe<Scalars['Float']['input']>;
  customerMileage?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Float']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  supportWorkerId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  travelWithCustomerDestination?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamMeetingInput = {
  apologies?: InputMaybe<Array<InputMaybe<ApologyInput>>>;
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meetingId: Scalars['String']['input'];
  note?: InputMaybe<NoteInput>;
  teamId: Scalars['String']['input'];
};

export type UpdateVisitProgressInput = {
  id: Scalars['String']['input'];
  progress: Scalars['String']['input'];
  rotaId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type Visit = {
  __typename?: 'Visit';
  activityType?: Maybe<Scalars['String']['output']>;
  birdieId?: Maybe<Scalars['String']['output']>;
  cancelledDateTime?: Maybe<Scalars['Float']['output']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<VisitMove>;
  endDateTime: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  isToBeRescheduled?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  oneOffVisitNote?: Maybe<Scalars['String']['output']>;
  oneOffVisitReason?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<VisitMove>;
  progress?: Maybe<Scalars['String']['output']>;
  rotaId?: Maybe<Scalars['String']['output']>;
  secondarySupportWorker?: Maybe<SupportWorker>;
  secondarySupportWorkerId?: Maybe<Scalars['String']['output']>;
  shiftId: Scalars['String']['output'];
  shiftRun?: Maybe<ShiftRun>;
  startDateTime: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  supportWorker?: Maybe<SupportWorker>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  travelTime?: Maybe<Scalars['Float']['output']>;
  travelWithCustomerDestination?: Maybe<Scalars['String']['output']>;
  travelWithCustomerMileage?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visitId: Scalars['String']['output'];
  visitImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visitIncidents?: Maybe<Array<Maybe<VisitIncident>>>;
  visitNotes?: Maybe<Scalars['String']['output']>;
  visitPlanResults?: Maybe<VisitPlanResult>;
  week?: Maybe<Scalars['String']['output']>;
  whoCancelled?: Maybe<Scalars['String']['output']>;
};

export type VisitByIdSearch = {
  id: Scalars['String']['input'];
  rotaId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type VisitCancellation = {
  __typename?: 'VisitCancellation';
  customerName?: Maybe<Scalars['String']['output']>;
  totalCancelledVisitTime?: Maybe<Scalars['Float']['output']>;
  totalCancelledVisits?: Maybe<Scalars['Float']['output']>;
  totalNumberOfVisits?: Maybe<Scalars['Float']['output']>;
  totalVisitTime?: Maybe<Scalars['Float']['output']>;
};

export type VisitCancellationSearch = {
  endDateTime?: InputMaybe<Scalars['Float']['input']>;
  startDateTime?: InputMaybe<Scalars['Float']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type VisitIncident = {
  __typename?: 'VisitIncident';
  actions?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  date?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supportWorkerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visitId: Scalars['String']['output'];
  whatHappenedBefore?: Maybe<Scalars['String']['output']>;
};

export type VisitIncidentByIdSearch = {
  customerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  visitId: Scalars['String']['input'];
};

export type VisitIncidentInput = {
  actions?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supportWorkerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visitId: Scalars['String']['input'];
  whatHappenedBefore?: InputMaybe<Scalars['String']['input']>;
};

export type VisitIncidentSearch = {
  customerId: Scalars['String']['input'];
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type VisitMove = {
  __typename?: 'VisitMove';
  fullName?: Maybe<Scalars['String']['output']>;
  rotaId?: Maybe<Scalars['String']['output']>;
  shiftId?: Maybe<Scalars['String']['output']>;
  supportWorkerId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  visitId?: Maybe<Scalars['String']['output']>;
};

export type VisitObservationType = {
  __typename?: 'VisitObservationType';
  care_recipient_id?: Maybe<Scalars['String']['output']>;
  caregiver_id?: Maybe<Scalars['String']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  visit_id?: Maybe<Scalars['String']['output']>;
};

export type VisitPlanResult = {
  __typename?: 'VisitPlanResult';
  activitiesPlan?: Maybe<Array<Maybe<ActivitiesPlan>>>;
  customerId: Scalars['String']['output'];
  dailyLivingPlan?: Maybe<Array<Maybe<DailyLivingPlan>>>;
  dateUpdated: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  medicationPlan?: Maybe<Array<Maybe<MedicationPlan>>>;
  rotaId: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
  visitId: Scalars['String']['output'];
};

export type VisitPlanResultInput = {
  activitiesPlan?: InputMaybe<Array<InputMaybe<ActivitiesPlanInput>>>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  dailyLivingPlan?: InputMaybe<Array<InputMaybe<DailyLivingPlanInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  medicationPlan?: InputMaybe<Array<InputMaybe<MedicationPlanInput>>>;
  rotaId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type VisitPlanResultSearch = {
  customerId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type VisitSearch = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type VisitType = {
  __typename?: 'VisitType';
  actual_end_at?: Maybe<Scalars['String']['output']>;
  actual_start_at?: Maybe<Scalars['String']['output']>;
  caregivers?: Maybe<Array<Maybe<CaregiverInVisitType>>>;
  end_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type VisitWithObservationsSearch = {
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type VisitWithObservationsType = {
  __typename?: 'VisitWithObservationsType';
  medication_task_instances?: Maybe<Array<Maybe<MedicationTaskInstancesType>>>;
  observations?: Maybe<Array<Maybe<VisitObservationType>>>;
};

export type WellbeingStatus = {
  __typename?: 'WellbeingStatus';
  date: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type WellbeingStatusInput = {
  date: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type Workshop = {
  __typename?: 'Workshop';
  body?: Maybe<Scalars['String']['output']>;
  candidates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  complete?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
};

export type WorkshopSearch = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type WorkshopSendEmailsInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  candidates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};
